import _ from 'lodash';
import { Observable } from 'lib0/observable'
import { helpers } from "@cargo/common";

class TouchDetector extends Observable {

	constructor() {
		super();

		this.pointerType = 'mouse';

		if(!helpers.isServer){

			window.addEventListener('pointerdown', this.onPointerMove);			
			window.addEventListener('pointermove', this.onPointerMove);
		}
		
		this.triggerChange();

	}

	onPointerMove = (e)=>{

		if( this.pointerType !== e.pointerType){
			this.pointerType = e.pointerType;
			this.triggerChange(e);
		}

	}

	triggerChange= (e)=>{
		this.emit('change',[this.pointerType, e]);
	}

}

export default new TouchDetector();