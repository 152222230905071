import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from "../actions";
import { HotKey } from "@cargo/ui-kit/hotkey/hotkey";
import { ProcessingAnimation } from "@cargo/ui-kit/processing/processing";
import { Formik, Field } from 'formik';
import { basicFormikChangeListener as FormikChangeListener } from "@cargo/common";
import { Input } from "@cargo/ui-kit/input/input";

const NewsletterSignupModal = (props) => {
	const dispatch = useDispatch();

	// Local state for error and sending status
	const [error, setError] = useState(false);
	const [sending, setSending] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	// A mutable ref to hold the "sending" flag outside of state
	const sendingRef = useRef(false);

	const invalidEmailMessage = 'A valid email is required';

	const validateEmail = (email) => {
		let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};

	const submitEmail = (email) => {
		dispatch(actions.newsletterSubscribe(email))
			.then((res) => {
				props.Alert.closeModal();
				setTimeout(() => {
					props.Message.showMessage({
						messageText: 'You are now subscribed',
						duration: 2000,
						preventClickout: false,
					});
				}, 780);
			})
			.catch((err) => {
				setSending(false);
				sendingRef.current = false;
				setError(true);
				setErrorMessage(err.data.errors.email ?? invalidEmailMessage);
			});
	};

	const validateAndSendEmail = (form) => {
		// Prevent duplicate sends
		if (sending || sendingRef.current) return;

		setSending(true);
		sendingRef.current = true;

		let inputVal = form.values['newsletter'];
		let validated = true;
		let errMsg = '';

		if (inputVal === "" || inputVal.length === 0 || !validateEmail(inputVal)) {
			validated = false;
			errMsg = invalidEmailMessage;
		}

		if (!validated && errMsg) {
			setSending(false);
			sendingRef.current = false;
			setError(true);
			setErrorMessage(errMsg);
		} else if (validated) {
			submitEmail(inputVal);
			setSending(false);
			sendingRef.current = false;
		}
	};

	const onChange = (changes, form) => {
		let key = Object.keys(changes)[0];
		if (error && key === 'newsletter') {
			setError(false);
			setErrorMessage('');
		}
	};

	return (
		<Formik initialValues={{ newsletter: '' }}>
			{form => (
				<>
					<HotKey
						shortcut="enter"
						config={{ keyCode: 13 }}
						callback={() => {
							validateAndSendEmail(form);
						}}
						scope="customalert"
					/>

					<HotKey
						shortcut="esc"
						config={{ keyCode: 27 }}
						callback={() => {
							if (!sending && !sendingRef.current) {
								props.Alert.closeModal();
							}
						}}
						scope="customalert"
					/>

					<div
						className="custom-child"
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								validateAndSendEmail(form);
							}
						}}
					>
						<FormikChangeListener onChange={onChange} />

						<Field
							component={Input}
							name="newsletter"
							placeholder="Enter your email address..."
							focusOnInit={true}
							className="input hover"
							noBars={true}
						/>

						{error && errorMessage ? (
							<div className="error-message">
								<span>{errorMessage}</span>
							</div>
						) : null}

						<div className="buttons">
							<button
								button-style="rounded-2"
								className="deny"
								onClick={() => {
									props.Alert.closeModal();
								}}
							>
								Cancel
							</button>

							<button
								button-style="rounded-2"
								className="confirm"
								style={{ position: 'relative' }}
								onClick={() => {
									validateAndSendEmail(form);
								}}
							>
								{sending ? (
									<ProcessingAnimation className="accent default-border-radius" />
								) : null}
								OK
							</button>
						</div>
					</div>
				</>
			)}
		</Formik>
	);
};

export default NewsletterSignupModal;