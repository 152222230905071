import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import App from "./components/App";
import { Frontload } from '@cargo/react-frontload';
import { initDarkMode } from './darkmode';
import { actionTypes, actions } from "./actions";
import { Provider } from 'react-redux';
import { Router } from "react-router";
import { Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import createStore from "./store";
import cargoFetch from '@cargo/fetch';
import { helpers, API_ORIGIN } from "@cargo/common";
import { isProduction, isLocalEnv, isServerRendered } from "@cargo/common/helpers";
import * as Sentry from "@sentry/browser";
import _ from 'lodash';
import Loadable from '@cargo/loadable-components';
import { routes, getRouteInfo } from './components/routes';
import LayeredRouter from '@cargo/layered-router';

export * from './ssr';
export const { store } = createStore();

if (!helpers.isServer) {

	try {

		// Remove legacy cookies that may contain auth data
		if (document.cookie) {
			document.cookie.split(';').forEach((cookie) => {
				const expiry = cookie.replace(/^ +/, '').replace(/=.*/, '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=');
				document.cookie = `${expiry}cargo.site`;
				document.cookie = `${expiry}${window.location.hostname}`;
			});
		}

		// kill legacy localstorage auth
		localStorage.removeItem('c3-auth');

	} catch (e) {
		console.error('Unable remove legacy cookies', e);
	}

	cargoFetch.on('rejected', (failedResponse, config) => {

		if (failedResponse.status === 403 && config.preventLogout !== true) {
			// unauthorized request. Assume login credentials are not valid (anymore);
			console.warn('Unauthorized request. Logout...');

			store.dispatch({
				type: actionTypes.AUTHENTICATE_USER_REJECTED,
				payload: {}
			});

		}

	});

	// store last scroll pos when leaving
	addEventListener('beforeunload', (event) => {
		try {
			sessionStorage.setItem('lastScrollPosition', document.scrollingElement.scrollTop);
		} catch (e) {
			console.error(e);
		}
	});

	document.addEventListener('DOMContentLoaded', function () {
		initDarkMode(store)
	}, false);

	// prevent auto scroll restoration by the browser
	if (window.history.scrollRestoration) {
		window.history.scrollRestoration = 'manual';
	}

	// create history object
	const history = createBrowserHistory();

	// Scroll restoration for page navigation
	const oldScrollPositions = {};

	// Helper function to get the appropriate scrollable element
	const getScrollElement = () => {
		return document.querySelector('#siteslist, .athenticated #templates') || document.scrollingElement;
	};

	const getScrollTop = () => {
		return getScrollElement().scrollTop;
	};

	const setScrollTop = (top = 0) => {
		getScrollElement().scrollTo(0, top);
	};

	const runScroll = (top = 0) => {
		setScrollTop(top);
		requestAnimationFrame(() => {
			setScrollTop(top);
		});
	};

	history.push = _.wrap(history.push, function (original, path, options = {}) {
		const newPathname = (typeof path === "string" ? path : path.pathname).replace(/\/$/, "");
		const oldPathName = decodeURIComponent(window.location.pathname.replace(/\/$/, ""));
		// Store scroll position of currently rendered content
		oldScrollPositions[oldPathName] = getScrollTop();
		// Call the original function
		var args = Array.prototype.slice.call(arguments);
		if (!options.preventScroll) {
			runScroll(options.restoreScroll ? oldScrollPositions[newPathname] : 0);
		}
		args.shift();
		original.apply(this, args);
	});

	// Back/forward buttons
	window.addEventListener('popstate', e => {
		const newPathname = decodeURIComponent(window.location.pathname.replace(/\/$/, ""));
		const oldPathName = history.location.pathname.replace(/\/$/, "");
		// Update scroll position of currently rendered content
		oldScrollPositions[oldPathName] = getScrollTop();
		runScroll(oldScrollPositions[newPathname]);
	});

	if (!isLocalEnv) {

		Sentry.init({
			environment: CARGO_ENV,
			release: BUILD_VERSION,
			dsn: "https://0813f9f6eb300310f23c630752900633@o4504992622247936.ingest.sentry.io/4506203124465664",
			integrations: integrations => {
				return [
					...integrations,
					new Sentry.BrowserTracing()
				]
			},
			tracesSampleRate: 0,
			tunnel: `${API_ORIGIN.replace('/v1', '')}/sentry/tunnel`
		});

	}

	// store listeners
	let currentAuthState;
	const onAuthChange = newAuthState => {

		if (currentAuthState !== newAuthState) {

			if (newAuthState === true) {
				document.body.classList.add('authenticated');
				document.body.classList.remove('unauthenticated');
			} else {
				document.body.classList.remove('authenticated');
				document.body.classList.add('unauthenticated');
			}

		}

		currentAuthState = newAuthState;

	}

	const onStoreChange = () => {
		const state = store.getState();
		onAuthChange(state.auth?.authenticated);
	}

	// run to set initial values
	onStoreChange();

	// subscribe to changes
	store.subscribe(onStoreChange);


	const searchParams = new URLSearchParams(
		// https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#preserving_plus_signs
		window.location.search.replaceAll('+', '%2B')
	);

	let authPromise;

	// remove token from search parameters
	if(searchParams.has('auth')) {

		// Auth got passed as a query string, use this. 
		try {
			
			store.dispatch({
				type: actionTypes.AUTHENTICATE_USER_FULFILLED,
				payload: {
					authenticated: true,
					data: JSON.parse(searchParams.get('auth'))
				}
			});

			// delete queryString
			searchParams.delete('auth');
			
			let newSearchParams = searchParams.toString();

			if(newSearchParams.length > 0) {
				newSearchParams = '?' + newSearchParams;
			}

			// remove from URL without reloading page
			window.history.replaceState({}, document.title, window.location.origin + window.location.pathname + newSearchParams);

		} catch(e) {
			console.error(e);
		}

		authPromise = Promise.resolve();

	} else {

		authPromise = store.dispatch(actions.getAuthToken({
			bounce: false
		}));

	}

	// we will only server render if we already know a user is logged out. Non SSR
	// rendered pages will have to first fetch auth to determine login status. Use this
	// to delay renders till we have this auth data
	//if (!isServerRendered) {
	//	authPromise = store.dispatch(actions.getAuthToken({
	//		bounce: false
	//	}));
	// } else {
	// 	authPromise = Promise.resolve();
	// }

	authPromise.finally(() => {

		let loggedHydrationMsg = false;
		const applicationDomNode = document.getElementById('app');
		const Application = (
			<Provider store={store}>
				<Router history={history}>
					<Frontload noServerRender={true}>
						<LayeredRouter routes={routes} getRouteInfo={getRouteInfo}>
							<App />
						</LayeredRouter>
					</Frontload>
				</Router>
			</Provider>
		);

		if (applicationDomNode.children.length > 0) {

			// preload modules used by SSR to generate the current HTML tree
			Loadable.loadSSRComponents().then(() => {

				// hydrate server render
				const appRoot = hydrateRoot(applicationDomNode, Application, {
					onRecoverableError: (error, errorInfo) => {
						// console.log(error, errorInfo)
						if(!loggedHydrationMsg) {
							console.log('[hydrateRoot] Skipped');
							loggedHydrationMsg = true;
						}
					}
				});

			})

		} else {

			// js render from scratch
			const appRoot = createRoot(applicationDomNode)
			appRoot.render(Application);

		}

	})

}