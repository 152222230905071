import { actionTypes } from "../actions";
import _ from "lodash";

const defaultSitePreviewState = {
	previewingSite: false,
	previewSiteModel: null,
	containingFolderID: null,
}

export default function(state =  defaultSitePreviewState, action){

	if(!action.payload) {
		return state;
	}

	switch(action.type) {
		
		case actionTypes.UPDATE_SITE_PREVIEW_STATE: 
			return _.merge({}, defaultSitePreviewState, state, action.payload);
		
	}

	return state 

};
