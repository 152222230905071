import React, { Component, useEffect, useState } from 'react';
import { NavLink, withRouter, Route, Switch, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../actions";
import "../css/home.scss";
import windowInfo from "@cargo/common/window-info";
import UIWindowLayer from "./ui-window-layer";
import { RemoteAlert } from "./ui-kit";
import { ContextMenu } from "@cargo/common/context-menu";
import { AnimationProvider } from "@cargo/common/animation-context";
import { DevGridLayer } from "@cargo/common/dev-grid-layer";
import { ToolTip } from "@cargo/common/tooltip";
import { IntercomButton } from '@cargo/common/intercom';
import { DroppableWrapper } from "./droppable-context-wrapper";
import TopMenuBar from "./top-menu-bar";
import LeftMenuBar from "./left-menu-bar";
import { Login } from "@cargo/common/login";
import _ from 'lodash';
import { isSafari, isMac, isIOS, isServer, isServerRendered } from "@cargo/common/helpers";
import { Alert, AlertContext } from "@cargo/ui-kit/alert/alert";
import { Message, MessageContext } from "@cargo/ui-kit";
import { HotKey } from "@cargo/ui-kit/hotkey/hotkey";
import { HotKeyManager } from "@cargo/ui-kit/hotkey/hotkey-manager";
import * as Sentry from "@sentry/browser";
import { IntercomProvider } from 'react-use-intercom';
import { INTERCOM_APP_ID } from "@cargo/common";
import CargoLogo from "./svg/cargo-logo.svg";
import { staticPageComponents, paths, routesByPath, routes} from './routes';
import SiteDuplicationProvider from './site-duplication-provider';
import WindowTitleManager from './window-title-manager'
import touchChangeListener from './touch-listener'

let currentHomepageViewport;
let fetchingInitialData = false;
let initialLoad = true;

export const IntercomSetup = (type) => { 

	return <AlertContext.Consumer>
		{(Alert) => (
			<IntercomProvider
				appId={INTERCOM_APP_ID}
				autoBoot={false}
				tunnelEnabled={INTERCOM_TUNNEL_ENABLED}
				tunnelPath={'/intercom.tunnel/'}
			>
				<IntercomButton 
					location='u.cargo' 
					sentry={Sentry}
					Alert={Alert}
					type={type}
					side={'left'}
				/>
			</IntercomProvider>
		)}
	</AlertContext.Consumer>

}

class App extends Component {
	
	constructor(props) {

		super(props);

		this.state = {
			devGridMode: false,
			isMobile: windowInfo.data.mobile.active,
			windowDimensions: {
				w: windowInfo.data.window.w,
				h: windowInfo.data.window.h,
			}
		}


		HotKeyManager.setScopeOrder(['priority', 'contextmenu', 'customalert', 'alert', 'preview', 'window', 'edit', 'default']);

		if(!isServer) {

			// check stored path and navigate there
			if(this.props.location.pathname === "/" && this.props.authenticated ) {
				try {

					const duplicatingViaQueryString = this.props?.location?.search && this.props.location?.search?.includes('?duplicate=')
					const joinSiteQueryString = this.props?.location?.search && this.props.location?.search?.includes('?site-join')

					const topLayer = _.last(this.props.layeredRouter.layerStack);

					if(topLayer.location.pathname === '/') {

						const lastVisitedFolder = localStorage.getItem('last-visted-slug')
						if( lastVisitedFolder && !duplicatingViaQueryString && !joinSiteQueryString ){
							this.props.history.replace(`/${lastVisitedFolder}`);
						}

					}

					if( joinSiteQueryString ){
						this.props.history.replace(`/`);
					}

				} catch(e) { console.error(e) }
			}

			// grab data needed to render
			if( this.props.authenticated && !fetchingInitialData ) {
				fetchingInitialData = true;
				this.fetchInitialData().then(()=>{
					this.props.fetchDeletedSites();
				});
			}

			this.setOSClass();


		}

	}

	onWindowResize = (forceUpdate) => {

		const data = windowInfo.data;

		const {
			w,
			h
		} = data.window;

		const {
			baseUnit
		} = data;

		const isMobile = windowInfo.data.mobile.active && windowInfo.data.mobile.portrait;



		this.setState((prevState)=>{

			if( w !== prevState.w || h!== prevState.h || forceUpdate ){

				document.documentElement.style.setProperty('font-size', baseUnit + '%'); 
				document.documentElement.style.setProperty('--viewport-width', w+'px');
				document.documentElement.style.setProperty('--viewport-width-unitless', w);
						
				windowInfo.applyGrid(isMobile);

			}


			if( isMobile !== prevState.isMobile || forceUpdate){

				this.props.updateHomepageState({
					isMobile
				});

				if (isMobile) {
					document.body.classList.add('mobile')
					document.documentElement.classList.add('mobile')
				} else {
					document.body.classList.remove('mobile')
					document.documentElement.classList.remove('mobile')
				}			
			}

			return {w,h,isMobile}

		});

	}

	setOSClass = () => {
		if (!isMac() && !isIOS()) {
			document.body.classList.add('f-f');
		}
	}
	
	fetchInitialData = () => {

		// don't run this multiple times
		if(this.initialDataPromise) {
			return this.initialDataPromise
		}

		const promises = [];

		if( !this.props.hasAccount ){
			promises.push(this.props.fetchAccount())
		}

		return this.initialDataPromise = Promise.all(promises);

	}

	componentDidUpdate(prevProps, prevState) {

		if(
			prevProps.authenticated !== true
			&& this.props.authenticated === true
		) {
			document.body.classList.remove('home');
			// clear old initial data promise
			delete this.initialDataPromise;
			// went from not authed to authed
			this.fetchInitialData().then(()=>{
				this.props.fetchDeletedSites();
				//this.router(this.props.location);
				this.props.updateHomepageState({requireLogin: false})
			});
			
		}

		if( prevProps.authenticated !== this.props.authenticated ){
			this.addBodyPathAttribute();
		}

		if( !prevProps.hasAccount && this.props.hasAccount ){
			this.duplicateFromQueryString(this.props.location);
		}

		if (this.props.location !== prevProps.location) {
			this.addBodyPathAttribute();
			this.updateThemeColorMetaTag();
		}

		if( this.props.darkMode !== prevProps.darkMode){
			this.updateThemeColorMetaTag();			
		}

		// Control when templates "fade in"
		if(this.props.layeredRouter?.layerStack?.[0]?.location?.pathname 
			!== prevProps.layeredRouter?.layerStack?.[0]?.location?.pathname
		){
			this.handleTemplatesAnimationFlag(this.props.layeredRouter?.layerStack?.[0], prevProps.layeredRouter?.layerStack?.[0]);
		}

		// if( this.props.routeInfo 
		// 	&& this.props.routeInfo.isCommunity !== prevProps.routeInfo.isCommunity
		// ){
		// 	AnimateElementOpacity('body', 1500, 0, { ease: [0.25, 0.1, 0.25, 1] } );
		// }

		if( this.props.suppressLoginForm === true ){
			setTimeout(()=>{
				this.props.updateHomepageState({ suppressLoginForm: false })
			}, 1000)
		}

	}

	componentDidMount() {

		this.updateThemeColorMetaTag();
		this.onWindowResize(true);
		windowInfo.on('window-resize', this.onWindowResize);

		touchChangeListener.on('change', (pointerType, event)=>{
			document.body.classList.remove('pointer-touch');
			document.body.classList.remove('pointer-pen');
			document.body.classList.remove('pointer-mouse');
			document.body.classList.add('pointer-'+pointerType);
			this.props.updateHomepageState({
				pointerType: pointerType,
			})
		});

		document.body.style.visibility = '';

		// preload CSS to prevent window flash
		import('@cargo/subscription-manager/css/account-settings-window.scss');

		this.addBodyPathAttribute();
		window.addEventListener("keydown", this.handleGlobalKeyDown);
		window.addEventListener("keyup", this.handleGlobalKeyUp);

		if( !this.props.authenticated ){
			this.duplicateFromQueryString(this.props.location);
		}

		setTimeout(()=>{
			initialLoad = false;
		}, 1500)

	}

	handleGlobalKeyDown = (e) => {
		if ( ( isMac() && e.metaKey === true ) || ( !isMac() && e.ctrlKey === true ) ) {
			document.body.classList.add('meta-key-pressed')
		}
	}

	handleGlobalKeyUp = (e) => {
		if ( ( isMac() && e.metaKey !== true ) || ( !isMac() && e.ctrlKey !== true ) ) {
			document.body.classList.remove('meta-key-pressed')
		}
	}

	updateThemeColorMetaTag = () => {
		const darkMode = this.props.homepageState?.darkMode;
		const matchedRoute = routes.find(route => route.path === this.props.match.path);
		const newColor = matchedRoute && matchedRoute.themeColor
  			? matchedRoute.themeColor[darkMode ? 'dark' : 'light']
  			: darkMode ? '#111111' : '#fff';
		const tag = document.querySelector('meta[name="theme-color"]');
		if (tag) {
			tag.setAttribute('content', newColor);
		}
	}

	addBodyPathAttribute = () => {
		const pathKeys = Object.keys(paths);
		const staticPathKeys = Object.keys(staticPageComponents);
		const baseLayer = this.props.layeredRouter.layerStack[0];


		// Clear old attribute
		document.body.removeAttribute('data-subroute');		
		document.body.removeAttribute('data-route');

		// Determine the new attribute value
		let newPath = '';

		if (this.props.match?.path === paths.STATIC_PAGES) {

			let staticLocation = this.props.location.pathname.replace(/\//, '');
			newPath = staticPathKeys.find(key => key === staticLocation);
			// newPath !== 'fonts' ? newPath = 'static' : newPath = 'fonts';
			// newPath = "static";
		} else {
			newPath = pathKeys.find(key => paths[key] === this.props.match?.path);
		}

		// Add the new data-route attribute if it exists
		if ( newPath ) {

			// add subroute attr so we can color the html bg according to its subroute
			if( newPath === 'COMMUNITY' && this.props.match?.params?.category ){
				document.body.setAttribute('data-subroute', this.props.match.params.category.toLowerCase().replace(/_/g, '-'));
			}

			document.body.setAttribute('data-route', newPath.toLowerCase().replace(/_/g, '-'));
		}

		if( this.props.match?.path === paths.JOBS || this.props.match?.path === paths.JOBVIEW ){
			document.body.setAttribute('cargo-jobs', "");
		} else {
			document.body.removeAttribute('cargo-jobs');
		}

		if( this.props.match?.path === paths.COMMUNITY ){
			document.body.classList.add('community');
		} else {
			document.body.classList.remove('community');
		}

		if( baseLayer?.routeInfo?.isTemplates ){
			document.body.setAttribute('slim-left-bar', "");
		} else {
			document.body.removeAttribute('slim-left-bar');
		}

		if( this.props.match?.path === paths.STATIC_PAGES || this.props.match?.path === paths.RATES ){
			document.body.setAttribute('cargo-information', "");
		} else {
			document.body.removeAttribute('cargo-information');
		}

		if( this.props.match?.path === paths.WRITING 
			|| this.props.match?.path === paths.WRITING_CATEGORY 
			|| this.props.match?.path === paths.USEFUL_MUSIC
			|| this.props.match?.path === paths.USEFUL_MUSIC_CATEGORY
		){
			document.body.setAttribute('cargo-writing', "");
		} else {
			document.body.removeAttribute('cargo-writing');
		}

		// Special case for the ROOT path when not authenticated
		if (this.props.match?.path === paths.ROOT && !this.props.authenticated) {
			document.body.setAttribute('data-route', 'home');
		}

		if( this.props.match?.path === paths.ROOT && this.props.authenticated ){
			document.body.setAttribute('data-route', 'folder');
		}

	};

	duplicateFromQueryString = (currLocation) => { 
		let searchParams = new URLSearchParams(window.location.search);
		let templateIdentifier = null;

		if( searchParams && searchParams.has('duplicate') ){

			// If we have ?duplicate=xxxx in the URL, get it
			templateIdentifier = searchParams.get('duplicate');
			// delete it
			searchParams.delete('duplicate');
			// remove query string from URL without reloading page
			window.history.replaceState({}, document.title, window.location.origin + window.location.pathname );
		}

		// If we're not authenticated, save the query string to redux for use after auth.
		if( !this.props.authenticated && templateIdentifier ){

			this.props.updateHomepageState({
				queryStringDuplicateId: templateIdentifier,
				'requireLogin': true
			});
			// Turn back, will re-run function after authentication
			return
		}

		// If we are authenticated
		if( this.props.hasAccount && this.props.authenticated ){
			// turn back if we don't have an ID to duplicate...
			if( !this.props.queryStringDuplicateId && !templateIdentifier ){
				return
			}
			// Otherwise use the value from redux, or straight from the URL
			templateIdentifier = this.props.queryStringDuplicateId ? this.props.queryStringDuplicateId : templateIdentifier;
			// and start duplicating a site.
			window.dispatchEvent(new CustomEvent('duplicate-site-from-query', {
				detail: {
					siteId: templateIdentifier
				}
			}));
		} 

	}

	handleTemplatesAnimationFlag = (baseRoute, prevBaseRoute) => {
		// Enable or disable the animation for the templates fade in
		// Must be done in app because the route can render fresh from
		// different paths, breaking the previous value flag continuity.
		let templatesAnimate = !prevBaseRoute?.match?.params?.folder && !baseRoute?.match?.params?.folder;
		if( templatesAnimate !== this.props.templatesAnimate ){
			this.props.updateHomepageState({
				'templatesAnimate': templatesAnimate,
			});
		}
	}

	render() {

		const matchedRoute = routes.find(route => route.path === this.props.match.path)
		

		// this route requires being logged in
		if(matchedRoute?.requireLogin === true && !this.props.authenticated) {

			if(this.props.suppressLoginForm) {
				return null;
			}

			return <Login 
				getAuthTokenAction={actions.getAuthToken} 
				animateIn={ initialLoad ? false : true }
				canCreateNewAccount={false}
			/>

		}

		const showingFolders = (matchedRoute.path == paths.ROOT && this.props.authenticated);

		// if the route is marked to render standalone, render it
		// by itself
		if(matchedRoute?.renderStandalone) {
			return this.props.layeredRouter.renderMainRoute();
		}

		const hasStaticPage = this.props.layeredRouter.layerStack ? this.props.layeredRouter.layerStack.some(obj => obj.routeInfo.isStaticPage === true) : false;
		let canCreateNewAccount = false;

		// never allow signups on mobile
		if(!this.props.isMobile) {
			canCreateNewAccount = (this.props.homepageState.siteQueuedForDuplication || this.props.homepageState.loginCanCreateNewAccount) ? true : false;
		}

		const homepageClassList = `${isSafari() ? 'safari ' : ''}${ hasStaticPage ? 'static-page-active' : ''}`.trim();
		const renderAuthenticatedLeftBar = this.props.authenticated 
			&& !this.props.routeInfo.isPublicFolder 
			&& !this.props.routeInfo.isCommunity 
			&& !this.props.routeInfo.isWriting 
			&& !this.props.routeInfo.isInformation 
			&& !this.props.routeInfo.isUsefulMusic;

		const renderTopNav = !this.props.routeInfo.isPublicFolder;

		return (
			<Alert>
				<div id="index-anchor" style={{height:0,width:0}}></div>
				{ !isServer ? ( <WindowTitleManager /> ) : null}
				<Message>					
					<AlertContext.Consumer>{(Alert) => (<RemoteAlert alert={Alert} />)}</AlertContext.Consumer>
					<ToolTip>
						<SiteDuplicationProvider>
						<ContextMenu>
						<AnimationProvider location={this.props.match.path}>
							
							{this.props.renderLoginForm ? ( 
								<Login 
									getAuthTokenAction={actions.getAuthToken} 
									canCreateNewAccount={ canCreateNewAccount } 
									templateID={this.props.siteQueuedForDuplication} 
									canClickout={ true }
									noOverlay={ true }
									onClickout={()=> { 
										this.props.updateHomepageState({
											'requireLogin': false,
											'siteQueuedForDuplication': null 
										})
									}}
									onLoginSuccess={() => {

										// always redirect to your "All" folder after logging in
										this.props.history.replace('/');

									}}
									loadingTillFormRemoval={true}
									animateIn={null}
								/>
							) : null }
							<DroppableWrapper>
								{this.props.layeredRouter.renderOverlays()}
								{renderTopNav ? ( <TopMenuBar id="main-menu" /> ) : ( null )}
								{renderAuthenticatedLeftBar ? (
									<LeftMenuBar />
								) : ( null )}
								<div id="manager" className={homepageClassList}>
									{this.props.layeredRouter.renderMainRoute()}
								</div>
								<DevGridLayer gridSystemEnabled={this.props.gridSystemEnabled} updateHomepageState={this.props.updateHomepageState} showGridLines={this.state.devGridMode} authenticated={this.props.authenticated} isMobile={this.props.isMobile} />
							</DroppableWrapper>

							{ this.props.routeInfo.isTemplates && this.props.authenticated ? <CargoLogo /> : null }

							<UIWindowLayer history={this.props.history} />


							<HotKey
								shortcut="cmd+shift+g"
								config={{ keyCode: 71, metaKey: true, shiftKey: true }}
								callback={() => {
									this.setState({devGridMode: !this.state.devGridMode})
								}}
								scope="default"
							/>
							
							<HotKey
								shortcut="esc"
								config={{ keyCode: 27 }}
								callback={() => {
									let activeWindow = Object.keys(this.props.activeUIWindows).length > 0;
									let alertActive = document.querySelector('.alert-window.active');
									let contextMenu = document.querySelector('.context-menu-layer');
									let loginWindow = document.querySelector('.login-window');
									let sitePreview = this.props.sitePreview.previewingSite;
									if( activeWindow || alertActive || contextMenu || sitePreview || loginWindow ){ return }
									document.activeElement.blur();
									this.props.history.push("/");
								}} 
								scope="default"
							/> 

						</AnimationProvider>
						</ContextMenu>
						</SiteDuplicationProvider>
					</ToolTip>

				</Message>
			</Alert>
		)
	}

}

function mapReduxStateToProps(state, ownProps) {
	return {
		homepageState: state.homepageState,
		hasAccount: state.homepageState.hasAccount,
		queryStringDuplicateId: state.homepageState.queryStringDuplicateId,
		authenticated: state.auth.authenticated,
		activeUIWindows: state.uiWindows.byId,
		userMeta: state.account?.meta,
		suppressLoginForm: state.homepageState.suppressLoginForm,
		sitePreview: state.sitePreview,
		pointerType: state.homepageState.pointerType,
		isMobile: state.homepageState.isMobile,
		templatesAnimate: state.homepageState.templatesAnimate,
		gridSystemEnabled: state.homepageState.gridSystemEnabled,
		renderLoginForm: state.homepageState.requireLogin,
		siteQueuedForDuplication: state.homepageState.siteQueuedForDuplication
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchAccount: actions.fetchAccount,
	fetchFolders: actions.fetchFolders,
	fetchDeletedSites: actions.fetchDeletedSites,
	addUIWindow: actions.addUIWindow,
	updateUIWindow: actions.updateUIWindow,
	removeUIWindow: actions.removeUIWindow,
	updateHomepageState: actions.updateHomepageState,
	updateUserMeta: actions.updateUserMeta,
	getAuthToken: actions.getAuthToken,
}, dispatch)

export default withRouter(connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(App));
