import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, NavLink, useLocation} from 'react-router-dom';

import _ from "lodash";

import AuthenticatedTopMenuBar from './authenticated-top-menu-bar';
import HomeMenu from './home-menu';

const TopMenuBar = ({ id, routeInfo, layeredRouter }) => {
	
	const baseRoute = layeredRouter?.layerStack?.[0]?.location.pathname;
	const authenticated = useSelector((state) => state.auth.authenticated);

	const location = useLocation();
	const [returnTo, setReturnTo] = useState("/")
	const [lastUrl, setLastUrl] = useState('');

	const showAsLoggedOut = routeInfo.isCommunity || routeInfo.isWriting || routeInfo.isUsefulMusic || routeInfo.isInformation;

	useEffect(() => {
		setLastUrl(baseRoute);
	}, [baseRoute]);

	useEffect(() => {
		// Check the last URL to determine whether we set the return path
		const shouldSetReturn = location &&
			baseRoute !== returnTo &&
			!baseRoute.includes('/community') &&
			!(routeInfo.isWriting || routeInfo.isUsefulMusic || routeInfo.isInformation);
	
		if (shouldSetReturn) {
			setReturnTo( baseRoute );
		}
	}, [location.pathname, routeInfo, lastUrl]);

	return (
		<div id={id} className={`top-menu-bar`}>

				{ authenticated && showAsLoggedOut ? ( <HomeMenu returnTo={returnTo} hasLinks={!routeInfo.isCommunity} /> ) : null }
				{ authenticated && !showAsLoggedOut ? ( <AuthenticatedTopMenuBar /> ) : null }
				{ !authenticated ? ( <HomeMenu hasLinks={true} /> ) : null }
		</div>
	);

}

export default withRouter(TopMenuBar);

