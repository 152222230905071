import { useRef } from 'react';

import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";

import { isSafari } from "@cargo/common/helpers";

import { useHistory } from "react-router-dom";
import { AlertContext } from "@cargo/ui-kit/alert/alert";
import { ContextMenuButton } from "@cargo/common/context-menu";
import Loadable from '@cargo/loadable-components'

const FolderContextUI = props => {

	const history = useHistory();
	const prevFolderSlugRef = useRef(props.folder.slug);
	let siteCount = props.folder?.sites?.length;

	const isSafariBrowser = isSafari();

	const deleteAndNavigate = () => {
		props.deleteFolder( props.folder?.id );

		if( history.location.pathname === `/${props.folder?.slug}` ){
			history.push('/');
		}
	}

	return (
		<>

		<AlertContext.Consumer>
		    {(Alert) =>
		    	<>
				<ContextMenuButton
					label={"Rename"} 
					onPointerUp={
						(e) => {

		            		Alert.openModal({
			            		header: 'Rename',
			            		type: 'dialog',
			            		placeholder: 'Rename Folder...',
								className: isSafariBrowser ? 'safari' : '',
			            		existingValue: props.folder.name,
			            		focusInput: true,
			            		ignoreUnmount: true,
			            		maxInputLength: 100,
			            		onConfirm: ( folderName ) => {
									
			            			if( folderName && props.folder){

			            				props.updateFolder({
			            					...props.folder,
			            					name: folderName
			            				}).then((response)=>{

			            					if( response.status !== 200 ){ return }

											let currentLowercaseSlug = history.location.pathname.toLowerCase();
											let prevLowercaseSlug    = prevFolderSlugRef.current ? prevFolderSlugRef.current.toLowerCase() : '';
											
											if( currentLowercaseSlug === `/${prevLowercaseSlug}` ){
				            					history.replace( response.data.slug );
											}

			            					// props.updateFolder({
				            				// 	name: response.data.name,
				            				// 	slug: response.data.slug
				            				// })


			            				});

			            			}
		            			}
		            		})


						}
				}></ContextMenuButton>
		    
				<ContextMenuButton 
					label="Remove" 
					onPointerUp = { e => { 
						if (siteCount > 0) {
							Alert.openModal({
								header: 'Remove Folder?',
								message: '(Sites will not be deleted)',
								type: 'confirm',
								ignoreUnmount: true,
								onConfirm: ( folderName ) => {
									deleteAndNavigate();
								}
							})
						} else {
							deleteAndNavigate();
						}
					}}
				/>	
				<hr/>
				<ContextMenuButton 
					label={ props.isPrivate ? "Share folder" : "Share (enabled)" }
					onPointerUp = { e => { 

						props.addUIWindow({
							group: 'right-menu-bar',
							component: Loadable({ loader: () => import('./share-folder-window')}),
							id: `share-folder-window`,
							props: {
								type: 'popover', 
								positionType: 'center', 
								windowName: 'share-folder',
								clickoutLayer: true,
								clickoutLayerDim: true,
								draggingUploadedImage: false,
								disableDragging: false,
								waitForHeightBeforeRender: true,
								minimumRenderHeight: 40,
								folderId: props.folder.id,
							}
						})
					}}

				/>	
		
				</>
		    }
		</AlertContext.Consumer>


		</>
	);

};

function mapReduxStateToProps(state, ownProps) {

	let permissions = state?.account?.permissions;
	const isInUseEditor = permissions ? permissions.find((site)=> site.role === 'Inuse' ) ? true : false : false;

	return {
		isInUseEditor: isInUseEditor,
		isPrivate: ownProps.folder.access_level === "PRIVATE"
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		updateFolder: actions.updateFolder,
		deleteFolder: actions.deleteFolder,
		addUIWindow: actions.addUIWindow
	}, dispatch);

}

export default connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(FolderContextUI);