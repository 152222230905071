import { actionTypes } from "../actions";

import _ from "lodash";

const defaultWritingState = {
    sectionsLoaded: [],
    articles: []
}

export default function (state = defaultWritingState, action) {

    if ( !action.payload ) {
        return state;
    }

    switch (action.type) {

        case actionTypes.FETCH_WRITING_PENDING:

            return {
                ...state
            }

            break;

        case actionTypes.FETCH_WRITING_REJECTED:

            return {
                ...state
            }

            break;

        case actionTypes.FETCH_WRITING_FULFILLED: 

            if(action.meta.id) {
                return {
                    ...state,
                    articles: _.uniqBy([
                        ...state.articles,
                        action.payload.data
                    ], 'id')
                }
            }

            const newArticles = action.payload.data;

            let newSectionsLoaded = [...state.sectionsLoaded];

            if(action.meta.section && !state.sectionsLoaded.includes(action.meta.section)) {
                newSectionsLoaded.push(action.meta.section)
            } else {
                // we loaded everything
                newSectionsLoaded = _.uniq(newArticles.map(a => a.section))
            }

            return {
                ...state,
                sectionsLoaded: newSectionsLoaded,
                // reverse this so we remove the first occurrence of any dupe articles instead of the last
                // this way we retain sort order from the API result (otherwise we remove the dupe article from the API collection that's merged in)
                articles: _.uniqBy([
                    ...state.articles,
                    ...newArticles
                ].reverse(), 'id').reverse()
            }

            break;

    }

    return state;

};