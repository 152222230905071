import React, { Component } from 'react';
import _ from 'lodash'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import { withRouter, NavLink} from 'react-router-dom';
import RowPage from "./templates/row-page";
import SiteScrollerHandler from './templates/site-scroller-handler';
import { MessageContext } from "@cargo/ui-kit/message/message-controller";
import { frontloadConnect } from '@cargo/react-frontload';

const frontload = async props => { 

	const frontloadPromises = [];

	if( !props.hasTemplates && !props.loadingTemplates ){
		frontloadPromises.push( 
			props.fetchTemplates()
		);
	}

	await Promise.allSettled(frontloadPromises);

}

class Templates extends Component {

	constructor(props) {

		super(props);

		this.justClosedSitePreview = false;

	}

	componentDidUpdate(prevProps, prevState) {

		if(prevProps.activeFolder !== this.props.activeFolder) {
			// update state with actively rendered folder
			this.props.updateHomepageState({
				renderedFolder: this.props.activeFolder?.id || null
			});
		}

		if( prevProps.sitePreview.previewingSite === true && this.props.sitePreview.previewingSite === false ){
			// Prevent clicking / pointer events on things after closing the site preview with mousedown.
			this.justClosedSitePreview = true;
			window.addEventListener('mouseup', ()=>{ 
				this.justClosedSitePreview = false;
			}, {once: true})
		}
	}

	componentDidMount = () => {

		// update state with actively rendered folder
		this.props.updateHomepageState({
			renderedFolder: this.props.activeFolder?.id || null
		});

		// set title
		// document.title = 'Cargo Templates';

	}

	componentWillUnmount = () => {
	
		this.props.updateHomepageState({
			renderedFolder: null
		});

	}

	renderMessage = () => (
		<div className="templates-message">
			<span>To make a site, visit us on your desktop browser.</span>
		</div>
	);

	render() {

		const title = 'Templates';
		const templateClasses = `${this.props.authenticated ? '' : 'no-auth'}${this.props.activeFolder ? ' filtered' : ''}${this.props.templatesAnimate ? 'animated' : ''}`.trim();

		return <>					

			<div id="templates" 
				className={templateClasses}
			>
				<MessageContext.Consumer>{(message) => (<>
					{/* detail view */}
					{this.props.activeFolder ? <>
						{<RowPage
							message = {message}
						/>}	
					</> : (<>
						{!this.props.authenticated && this.props.isMobile && this.renderMessage()}
						<div className={`sections${!this.props.authenticated ? ' start' : ''}`}>
							{this.props.authenticated && this.props.isMobile && this.renderMessage()}
							<SiteScrollerHandler
								message={message}
								history={this.props.history}
							/>
						</div>
					</>)}
				</>)}</MessageContext.Consumer>
			</div>
		</>

	}

}

function mapReduxStateToProps(state, ownProps) {

	const activeFolderPath = ownProps.match.params?.folder ?? null;

	return {
		activeFolder:  state.templates.find(folder => folder.slug === activeFolderPath && folder.slug !== 'saved' ),
		authenticated: state.auth.authenticated,
		rootFolder: state.folders.find(folder => folder.slug === 'all'),
		isLocal: CARGO_ENV === 'localhost',
		userID: state.account?.id,
		sitePreview: state.sitePreview,
		hasTemplates: state.homepageState.hasTemplates,
		loadingTemplates: state.homepageState.loadingTemplates,
		uiWindows: state.uiWindows,
		isUserFolder: state.homepageState.isUserFolder,
		isMobile: state.homepageState.isMobile,
		templatesAnimate: state.homepageState.templatesAnimate,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		fetchTemplates: actions.fetchTemplates,
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow,
		updateHomepageState: actions.updateHomepageState,
	}, dispatch);

}


export default withRouter(connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(
	frontloadConnect(frontload, {
		onMount: true,
		onUpdate: false
	})(
		Templates
	))
)