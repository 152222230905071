

import React, {useState, useEffect, useRef} from 'react';
import _ from 'lodash';
import { Button } from "@cargo/ui-kit/button/button";
import './dev-grid-layer.scss';

export const DevGridLayer = ({
    updateHomepageState, 
    gridSystemEnabled, 
    showGridLines, 
    authenticated,
    isMobile
}) => {

    const [isChecked, setIsChecked] = useState(gridSystemEnabled);

    useEffect(() => {
        updateHomepageState({gridSystemEnabled: isChecked});
    }, [isChecked]);

    const buttonStyle = {
        backgroundColor: isChecked ? '#c8ddba' : '#808080',
        color: 'var(--ui-color-default)'
    };

    const gridStyle = isChecked ? (
        // Commented out is square default.
        // `body { 
        //     --grid-system-rows: repeat(var(--grid-row-count), 1fr); 
        //     --grid-subcell-height: calc(var(--grid-height-sans-pad) / 15);
        // }`
        // `body {
        //         --grid-system-rows: repeat(var(--grid-row-count), calc(var(--grid-width-sans-pad) / var(--grid-column-count)));
        //         --grid-subcell-height: calc(var(--grid-width-sans-pad) / calc(var(--grid-subcell-count) * var(--grid-column-count)));
        //         --grid-cell-height: calc(var(--grid-width-sans-pad) / var(--grid-column-count));
        // }`
        `body {
            --panel-cap: 2560;
        }`
    ) : ( `` );

    let count = isMobile ? 32 : 64;

    const cells = Array(count).fill(null).map((_, index) => (
        <div className="cell" key={index}>
          {Array(9).fill(null).map((_, subIndex) => (
            <div className="sub-cell" key={subIndex}></div>
          ))}
        </div>
    ));

    return (<>

        <style>
            {gridStyle}
        </style>
        { showGridLines ? (<>
            <Button style={buttonStyle} className={"grid-toggle"} onClick={()=> { setIsChecked(!isChecked) }}>
                {/* {isChecked ? 'True' : 'False'} */}
            </Button>
            <div id="dev-grid">
                <div className="one"></div>
                <div className="two"></div>
                <div className="three"></div>
                <div className="four"></div>
            </div>
            <div id="dev-grid-layer">
                {cells}
            </div>
        </> ) : null }
    </>)

};
