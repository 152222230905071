import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../../actions";
import _ from 'lodash'
import { withRouter } from 'react-router-dom';
import Site from "../site";
import { SectionHeader } from "./section-header";
import { ElementScrollEffect } from '@cargo/ui-kit/element-scroll-effect/element-scroll-effect';
class RowPage extends Component {

	constructor(props) {

		super(props);

		this.state = {
			streamsLoaded: false,
			siteRefs: []
		}

		this.ticking = false;
		this.fullyPaginated = false;

	}


	setupSiteRefs = () => {

		if (this.props.sitesInFolder && this.props.sitesInFolder.length > 0) {
			let siteRefs = [];
			this.props.sitesInFolder?.map(site => {
				if (site) {
					siteRefs[site.id] = React.createRef();
				}
			})
			this.setState({siteRefs: siteRefs})
		} else {
			this.setState({siteRefs: []})
		}

	}

	componentDidUpdate(prevProps, prevState) {

			// if changing the preview site model
		if ( prevProps.previewingSite === true && this.props.previewingSite === false
		) {
			let siteRef = this.state.siteRefs ? this.state.siteRefs[prevProps.previewSiteModel.id] : null;
			this.scrollToSite(siteRef)
		}

		if (prevProps.sitesInFolder !== this.props.sitesInFolder && this.props.sitesInFolder.length > 0) {
			this.setupSiteRefs();
		}

	}

	componentDidMount = () => {

		// window.addEventListener('scroll', this.onScroll, {passive: true})

		if (this.props.sitesInFolder.length > 0) {
			this.setupSiteRefs();
		}

	}

	componentWillUnmount = () => {
		// window.removeEventListener('scroll', this.onScroll)
	}

	scrollToSite = (siteRef) => {
		if (siteRef?.current) {
			// Scrolling while preview is open doesn't work. Scroll just after close on delay.
			setTimeout(()=> { 
				siteRef.current.scrollIntoView({ block: "center", inline: "nearest" }) 
			}, 1);
		}
	}

	// check 10 times per second max
	onScroll = _.throttle((e)=> {
		// const last_known_scroll_position = window.scrollY + window.innerHeight;
		const last_known_scroll_position = e.target.scrollTop + e.target.clientHeight;

		if ( !this.ticking && !this.fullyPaginated && !this.props.activeFolder.fullyPaginated ) {

			window.requestAnimationFrame(() => {
				if(last_known_scroll_position > e.target.scrollHeight - (screen.height * 2)){

					this.ticking = true;
						
					this.props.paginateTemplateSites(this.props.activeFolder)
						.then((res)=> {
							// Check if we are still returning sites
							if( res.data.sites.length === 0 ){
								// If our call is empty, we are at the end of our data set.
								this.fullyPaginated = true;
							}
						}).finally(() => {
							this.ticking = false;
						})

				}

			});

		}

	}, 100)

	render() {
		const classes = `rows row-page`;
		return (
			<>
 				<div id="siteslist" className={classes} onScroll={this.onScroll}> 
					<SectionHeader folder= { this.props.activeFolder } />
					{ this.props.sitesInFolder.map((site, index) => (
						<Site  
							key={`row-page-site-${site.id}`}  
							index={site.id}
							site={site}

							containingFolderID={this.props.activeFolder.id}
							forwardedRef={this.state.siteRefs ? this.state.siteRefs[site.id] : null} 
							message={this.props.message}
							siteUnavailable={ site?.is_coming_soon ?? false }
							rowPage={true}
						/> 
					))}
					{ this.state.siteRefs.length > 0 && !this.props.authenticated ? (
						<ElementScrollEffect 
							elementReferences={this.state.siteRefs} 
							authenticated={true}
						/>
					) : null }
 				</div> 
			</>
		)
	}

}

function mapReduxStateToProps(state, ownProps) {

	const activeFolderSlug = ownProps.match.params?.folder ?? null;
	const activeFolder = state.templates.find(folder => folder.slug === activeFolderSlug);
   	const sitesInFolder = activeFolder.sites;

	return {
		folders: state.templates,
		activeFolder: activeFolder,
		sitesInFolder: sitesInFolder,
		previewingSite: state.sitePreview.previewingSite,
		previewSiteModel: state.sitePreview.previewSiteModel,
		authenticated: state.auth.authenticated
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		paginateTemplateSites: actions.paginateTemplateSites,
	}, dispatch);

}


export default withRouter(connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(RowPage))