import React from 'react';
import { matchPath, __RouterContext } from "react-router";
import { withRouter } from 'react-router-dom';
import { connect, shallowEqual } from 'react-redux';
import _ from 'lodash';

class LayeredRouter extends React.Component {
	
	constructor(props) {

		super(props);
		
		const initialLocation = this.props.location;

		// router stack contains a { match, location, history } item for every layer
		this.state = {
			layerStack: this.getNextLayerStack([], this.props.location),
			renderMainRoute: () => {
				return this.renderLayers(0,1)
			},
			renderOverlays: () => {
				return this.renderLayers(1)
			},
			closeOverlay: () => {

				if(this.state.layerStack.length <= 1) {
					return;
				}

				const newStack = [...this.state.layerStack];

				newStack.pop();

				// update URL to reflect parent layer URL
				const parent = _.nth(newStack, -1);

				if(parent) {
					this.props.history.push(parent.location.pathname);
				}

				// set new stack
				this.setState({
					layerStack: newStack
				})

			},
			getInitialRouteOnLoad: () => {
				return initialLocation;
			}
		};

		this.layerStateCache = new Map();

	}

	extendRouterContext(routerContext, layer) {

		if(!layer) {
			return routerContext;
		}

		const newContext = {
			...routerContext,
			routeInfo: layer.routeInfo, 
			layeredRouter: this.state,
			layerIndex: this.state.layerStack.indexOf(layer),
			match: layer.match, 
			location: layer.location
		}

		const lastContext = this.layerStateCache.get(layer.id);

		if(_.isEqual(newContext, lastContext)) {
			return lastContext;
		}

		this.layerStateCache.set(layer.id, newContext);

		return newContext;

	}

	renderLayers(start, end) {

		return this.state.layerStack.slice(start, end).map(layer => {

			// render every layer with it's own route context
			return <React.Fragment key={layer.id}>
				<__RouterContext.Consumer>
					{routerState => {
						const context = this.extendRouterContext(routerState, layer);

						return <__RouterContext.Provider value={context}>
							{layer.route.render?.({
								layer, 
								context, 
								routeProps: this.props.memoizedRouteProps.get(layer.route.mapStateToProps) || {}, 
								router: this
							}) || null}
						</__RouterContext.Provider>
					}}
				</__RouterContext.Consumer>
			</React.Fragment>

		});

	}

	render() {

		return <__RouterContext.Consumer>
			{routerState => {
				const context = this.extendRouterContext(routerState, this.state.layerStack[0]);
				return <__RouterContext.Provider value={context}>
					{this.props.children}
				</__RouterContext.Provider>

			}}
		</__RouterContext.Consumer>

	}

	getLayerConfigForLocation = location => {
		
		let match = null;
		let route = null;
		
		for (const routeConfig of this.props.routes) {

			if(routeConfig.ssrOnly) {
				continue;
			}

			match = matchPath(location.pathname, { path: routeConfig.path, exact: routeConfig.exact ?? true });
			route = routeConfig;

			if(match) {
				// only match the first route we find
				break;
			}

		}

		return {
			id: _.uniqueId('layer-'),
			match,
			location,
			// make route immutable so we cache getters like isOverlay in their current state
			route: {...route},
			routeInfo: this.props.getRouteInfo ? this.props.getRouteInfo({match}) : {}
		}

	}

	addBaseRoute = (layerStack, nextLayer, nextLocation) => {

		// get base route URL
		let basePath;

		if(nextLayer.route.basePath) {

			if(typeof nextLayer.route.basePath === "function") {
				// passed as a callback fn
				basePath = nextLayer.route.basePath(nextLayer);
			} else {
				// passed as a string
				basePath = nextLayer.route.basePath
			}

		}

		layerStack.unshift(
			this.getLayerConfigForLocation({
				...nextLocation,
				pathname: basePath || '/',
				key: Math.random().toString(36).substr(2, 8)
			})
		)

	}

	getNextLayerStack = (currentLayerStack, nextLocation) => {

		let currentLayer = _.nth(currentLayerStack, -1);
		const parentLayer = _.nth(currentLayerStack, -2);
		const nextLayer = this.getLayerConfigForLocation(nextLocation);

		// console.log({
		// 	currentLayer,
		// 	parentLayer,
		// 	nextLayer
		// })
		
		let newLayerStack = [...currentLayerStack];

		if(
			!currentLayer 
			|| ( 
				// next route is an overlay
				nextLayer.route.isOverlay 
				// and that overlay is a different route
				&& currentLayer?.route.path !== nextLayer?.route.path
				// and we're not going back to the parent overlay's route
				&& parentLayer?.route.path !== nextLayer?.route.path
			)
		) {

			// When directly opening an overlay we need to make sure the main site
			// is rendered below it. Do this by adding a stack item for the site's root route
			if(!currentLayer && nextLayer.route.isOverlay) {
				this.addBaseRoute(newLayerStack, nextLayer, nextLocation);
			}

			// If no stack item exists yet, or if the new stack item is an overlay, create a new stack
			newLayerStack.push(nextLayer)
			
		} else {

			if(
				newLayerStack.length > 1
				&& _.nth(newLayerStack, -1).route.isOverlay
				&& (
					// navigated back to the parent layer route
					parentLayer?.route.path === nextLayer?.route.path
					// or routed to a different layer
					|| currentLayer?.route.path !== nextLayer?.route.path
				)
			) {

				// kill top layer from stack
				newLayerStack.pop();

				currentLayer = parentLayer;
			}

			// update current stack item
			newLayerStack = newLayerStack.map(layer => {

				if(layer === currentLayer) {
					// update the current stack item
					return {
						...nextLayer,
						// retain the ID
						id: layer.id
					}
				}

				// leave other stack items as-is
				return layer;

			})


			// ensure we have an underlay
			if(newLayerStack[0]?.route.isOverlay !== false) {
				this.addBaseRoute(newLayerStack, nextLayer, nextLocation);
			}

		}

		return newLayerStack

	}

	route = (nextLocation) => {

		const newLayerStack = this.getNextLayerStack(this.state.layerStack, nextLocation);

		this.setState({
			layerStack: newLayerStack
		})

	}

	shouldComponentUpdate(nextProps) {

		if(
			this.props.location !== nextProps.location
			|| this.props.memoizedRouteProps !== nextProps.memoizedRouteProps
		) {
			// update routes the URL or any of the route props change
			this.route(nextProps.location);
		}

		return true;
	}


}

export default withRouter(connect(
	state => {

		const memoizedRouteProps = new Map();
		let lastRoutePropCache = memoizedRouteProps;

		return (state, ownProps) => {

			let foundChange = false;

			// check to see if any dependencies declared by routes have changed
			ownProps.routes.forEach(route => {
				if(route.mapStateToProps) {

					const newRouteProps = route.mapStateToProps(state);

					if(!shallowEqual(memoizedRouteProps.get(route.mapStateToProps), newRouteProps)) {
						memoizedRouteProps.set(route.mapStateToProps, newRouteProps);
						foundChange = true;
					}

				}
			})

			if(foundChange) {
				// we got a change. Generate a new map so we trigger a router re-render
				lastRoutePropCache = new Map(memoizedRouteProps);
			}

			return {
				memoizedRouteProps: lastRoutePropCache
			}
		}

	}
)(LayeredRouter));