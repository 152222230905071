import React, { useEffect, useMemo, useRef, useState, useLayoutEffect, useContext } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { frontloadConnect } from '@cargo/react-frontload';
import { actions } from "../actions";
import { isServer } from "@cargo/common/helpers";
import CommunityMenu from './community-menu';
import { writingCategoryRoutes } from './routes';
import { Formik } from 'formik';
import { Alert, AlertContext, Button } from "@cargo/ui-kit";
import * as helpers from "@cargo/common/helpers";
import { Message, MessageContext } from "@cargo/ui-kit/message/message-controller";
import NewsletterSignupModal from "./newsletter-signup-modal"

const isSafariBrowser = helpers.isSafari();

// Convert tarot image name to image object from S3 bucket.
const processTarotImage = (tarotImageName) => {

    if (!tarotImageName || tarotImageName.length === 0) return null;
    const isReversed = tarotImageName.includes('-reversed');
    const cleanName = tarotImageName.replace('-reversed', '');
    
    // Capitalize the first letter of each word, unless it's "of"
    const capitalizeWord = (word) => word === 'of' ? word : word.charAt(0).toUpperCase() + word.slice(1);

    let readableName = cleanName
        .replace(/-/g, ' ')
        .split(' ')
        .map(capitalizeWord)
        .join(' ') + (isReversed ? ' (reversed)' : '');

    const baseUrl = 'https://static.cargo.site/assets/tarot/cards/';
    const src = isReversed
        ? `${baseUrl}reversed/${cleanName}.gif`
        : `${baseUrl}regular/${cleanName}.gif`;

    return { src, alt: readableName, caption: readableName };
};

// Convert uploaded I Ching image object to JSX.
const processIChingImage = (iChingImage) => {
    if (!iChingImage) return null;

    const src = `https://freight.cargo.site/m/${iChingImage.hash}/${iChingImage.name}`;
    const caption = iChingImage.caption;

    return { src, alt: iChingImage.name, caption };
};

const OracleImage = ({ image, type }) => (
    <div className={`oracle-image ${type}`}>
        <img src={image.src} alt={image.alt} />
        <div
            className="oracle-image-caption"
            dangerouslySetInnerHTML={{ __html: image.caption }}
        />
    </div>
);

function renderFullTitle(section) {
    switch (section) {
        case 'opening_text':
            return (
                <>Company Writing</>
            );
        case 'site_reviews':
            return <>Sites in Use</>;
        case 'goings_online':
            return <>W.W.W.</>;
        case 'oracle':
            return <>Oracle</>;
        default:
            return null;
    }
}

function renderDescription(section) {
    switch (section) {
        case 'opening_text':
            return (
                <>Collected below are the texts that start our “weekly” newsletter. They are offered as something like evidence — proof of our (sometimes joyful, sometimes gloomy, sometimes electrified) struggle to exist and participate in the world. There is a kind of solidarity here too, by way of making a <i>typically private process</i>, public.<br /></>
            );
        case 'site_reviews':
            return <>For us, “personal sites” are the most effective/eloquent tool for the contextualization of creative work. Every week we feature a particular Cargo site in our newsletter — we find these sites to be lovely, well-designed and/or interesting in some sincere way. Collected below are the texts that accompany them.<br /></>;
        case 'goings_online':
            return <>Collected below are clusters of links to non-Cargo sites featured in our “weekly” newsletter; the range (of subjects) is relatively wide and relatively personal. The spirit is a kind of refutation; a rejection of the (common) acceptance of a shrinking, increasingly featureless, corporate, overly self-referential and hyperbolically topical world.<br /></>;
        case 'oracle':
            return <>Each week we consult the Tarot and the I Ching. Sometimes the questions are submitted from users, the rest, come from us. The results are published in our weekly newsletter and collected below.<br /><br />The spirit of this endeavor is wholly serious but somewhat atypical (that is, not necessarily in line with the common practice of prediction/prophecy). Our usage is to aid in the breaking apart of blockages (whether creative and/or emotional) to push problems/blockages to interact with the productive randomness of the archetypal/aphoristic systems of the I Ching and the Tarot.<br /><br />To submit a question, send an email to <a target="_blank" href="mailto:oracle@cargo.site" rel="noopener noreferrer">oracle@cargo.site</a><br />To learn more about the I Ching <a target="_blank" href="https://en.wikipedia.org/wiki/I_Ching" rel="noopener noreferrer">click here</a><br />To learn more about the Tarot <a target="_blank" href="https://en.wikipedia.org/wiki/Tarot" rel="noopener noreferrer">click here</a></>;
        default:
            return null;
    }
}

const WritingArticleComponent = props => {

    if(!props.article) {
        return null;
    }

    const { id, section, title, tarot_image, media, content } = props.article;
    const routeObj = writingCategoryRoutes.find(crdm => crdm.section === section);
    const routePathMatch = routeObj ? routeObj.matchPath : 'writing';

    const dinkusPatterns = [
        /\*\*\*/g,
        /\* \* \*/g,
        /\* &nbsp;\* &nbsp;\*/g,
        /\* &nbsp; \* &nbsp; \*/g,
        /❉ ❉ ❉/g,
        /❉ &nbsp;❉ &nbsp;❉/g,
        /﹡﹡﹡/g
    ];
    
    function replaceDinkus(content) {
        dinkusPatterns.forEach(pattern => {
            content = content
                .replace(new RegExp(`${pattern.source}\\s*<br>`, 'g'), '<hr />')
                .replace(new RegExp(`<div[^>]*>\\s*${pattern.source}\\s*<\\/div>`, 'g'), '<hr />')
                .replace(new RegExp(`</div>${pattern.source}`, 'g'), '</div><br /><hr />')
                .replace(pattern, '<hr />')

        });
        return content;
    }

    // format article
    const formattedContent = replaceDinkus(content);

    // Process images
    const tarotImage = processTarotImage(tarot_image);
    const iChingImage = processIChingImage(media?.[0]);
    // Regex to validate URL
    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/.*)?$/;
    
    return (
        <div
            ref={props.innerRef}
            key={id}
            data-article-id={id}
            id={`${id}`} // Use article id directly for hash linking
            className={`article-block${props.directLink ? ' direct-link' : ''}`}
            data-article-section={section} // Changed attribute to data-attribute for validity
        >
            <div className="article-content">
                <div className="article-title">
                    {section === 'site_reviews' && urlRegex.test(title) ? (
                        <>
                            {!props.directLink && (
                                <a href={`/company-writing/${routePathMatch}/${id}`}>
                                    {props.numberedTitle}
                                    <br />
                                </a>
                            )}
                            <a href={`https://${title}`} target="_blank" rel="noopener noreferrer">
                                {title}
                            </a>
                        </>
                    ) : (
                        props.directLink ? title : <a href={`${section === 'opening_text' ? '/company-writing' : `/company-writing/${routePathMatch}`}/${id}`}>
                            {title}
                        </a>
                    )}
                </div>

                {section === 'oracle' && (tarotImage || iChingImage) ? (
                    <>
                        <div className="oracle-images">
                            {tarotImage && <OracleImage type="tarot" image={tarotImage} />}
                            {iChingImage && <OracleImage type="iching" image={iChingImage} />}
                        </div>
                        <br />
                    </>
                ) : ( 
                    section !== 'goings_online' && <br />
                )}

                <div
                    className="article-body"
                    dangerouslySetInnerHTML={{ __html: formattedContent }}
                />
            </div>
        </div>
    );

}

export const WritingArticle = withRouter(connect(
    function (state, ownProps) {

        return {
            article: state.writing.articles.find(article => article.id === ownProps.id)
        };

    },
    function mapDispatchToProps(dispatch) {
        return bindActionCreators({
            fetchWriting: actions.fetchWriting
        }, dispatch);
    }
)(frontloadConnect(async props => { 

    if(!props.article) {
        await props.fetchWriting({
            id: props.id
        });
    }

}, {
    onMount: true,
    onUpdate: false
})(
    WritingArticleComponent
)));

const WritingPageComponent = ({
    actions,
    isMobile,
    location,
    match,
    category,
    categoryMatch,
    effectiveSection,
    articles,
    hasWritingSection,
    directLink,
    ...props
}) => {

    const dispatch = useDispatch();
    // Track last selected article
    const [visibleArticleId, setVisibleArticleId] = useState(null);
    // Tracks initial scroll
    const [hasScrolled, setHasScrolled] = useState(false);
    // Tracks initial scroll
    const [newsletterSubmitted, setNewsletterSubmitted] = useState(false);

    const Alert = useContext(AlertContext);
    
    const firstRender = useRef(true);

    // Filter articles by the effective section
    const categoryArticles = useMemo(() => {
        return articles?.filter(article => article.section === effectiveSection) || [];
    }, [articles, effectiveSection]);

    const updatedCategoryArticles = useMemo(() => {
        return categoryArticles.map((article, index) => {
            return {
                ...article,
                numberedTitle: String(categoryArticles.length - index).padStart(5, '0')
            };
        });
    }, [categoryArticles]);

    // Get currentArticleId from the hash (e.g., #123)
    const currentArticleId = useMemo(() => {
        return location.hash ? location.hash.slice(1) : null;
    }, [location.hash]);

    // Refs for each article block
    const activeLinkRef = useRef(null);
    // this needs to be a ref because a state value will be cached
    // in the IntersectionObserver callback body
    const scrollState = useRef({
        scrolling: false,
        currentScrollEvtCallback: null
    });

    // Scroll to the article on first load or hash change
    if(!isServer) {
        useLayoutEffect(() => {
            if (!hasWritingSection || !currentArticleId || hasScrolled) return;

            setTimeout(() => {
                scrollToArticle(currentArticleId);
                setTimeout(() => {
                    scrollToArticle(currentArticleId);
                    setTimeout(() => {
                        scrollToArticle(currentArticleId);
                    }, 150);
                }, 100);
            }, 50);
        }, [currentArticleId, categoryArticles, hasWritingSection, hasScrolled]);
    }

    // Fetch articles if we haven't already
    useEffect(() => {
        if(!hasWritingSection && !firstRender.current) {
            props.fetchWriting({
                section: effectiveSection
            })
        }
    }, [effectiveSection]);

    useEffect(() => {
        if( !centerNavRef.current){ return; }
        centerNavRef.current.scrollTo(0, 0);
    }, [categoryArticles]);

    const viewportBoundaryObserverRef = useRef(null);

    useEffect(() => {

        let scrollIntoViewRequested = false;

        const onScroll = () => {

            if(!scrollIntoViewRequested && (!scrollState.current.scrolling || !hasScrolled)) {
                scrollIntoViewRequested = true;
                requestAnimationFrame(() => {
                    const rect = activeLinkRef.current?.getBoundingClientRect();
                    const scrollOffset = 0;
                    if(rect) {
                        centerNavRef.current?.scrollTo({
                            top: ((rect.top + centerNavRef.current.scrollTop) - (window.innerHeight / 2) + scrollOffset),
                            behavior: 'smooth'
                        });
                    }
                   scrollIntoViewRequested = false;
                })
            }
        }

        document.addEventListener('scroll', onScroll);

        viewportBoundaryObserverRef.current = new IntersectionObserver((entries, o) => {
            entries.forEach(entry => {

                if(!scrollState.current.scrolling && entry.isIntersecting) {
                    setVisibleArticleId(entry.target.dataset.articleId);
                }

            })
        }, {
            // https://www.w3.org/TR/intersection-observer/#intersection-observer-interface
            root: null, // "document" is not an element.
            // set margin to a single line 20% below the top of viewport
            rootMargin: '-20% 0px -80% 0px',
            threshold: [0, 1]
        });

        if (firstRender.current) {
            firstRender.current = false;
        }

        return () => {
            viewportBoundaryObserverRef.current.disconnect();
            document.removeEventListener('scroll', onScroll);
        }

    }, [])

    const scrollToArticle = (id) => {

        const yOffset = -40;
        const element = document.getElementById(id);

        if(!element) {
            return;
        }

        const y = element.getBoundingClientRect().top + window.scrollY + yOffset + 0.5;

        // kill previous listener
        if(scrollState.current.currentScrollEvtCallback) {
            scrollState.current.currentScrollEvtCallback.cancel();
            document.removeEventListener('scroll', scrollState.current.currentScrollEvtCallback);
        }

        // scroll ends when no scroll happened in the last 100ms
        const onScrollEnd = _.debounce(() => {
            scrollState.current.scrolling = false;
            document.removeEventListener('scroll', onScrollEnd);
            scrollState.current.currentScrollEvtCallback = null;
        }, 100);

        document.addEventListener('scroll', onScrollEnd);

        scrollState.current.scrolling = true;
        scrollState.current.currentScrollEvtCallback = onScrollEnd;

        window.scrollTo({ top: y, behavior: 'smooth' });

        setVisibleArticleId(id);

        setHasScrolled(true);

    };

    // Prevent scrolling of parent container when over scrolling within the navigation.
    const centerNavRef = useRef(null);

    useEffect(() => {

        if (!isMobile) {
            const nav = centerNavRef.current;

    
            const handleWheel = (e) => {
                const { scrollTop, scrollHeight, clientHeight } = nav;
                const atTop = scrollTop === 0;
                const atBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
                const scrollingUp = e.deltaY < 0;
                const scrollingDown = e.deltaY > 0;
    
                // If at the top of nav and trying to scroll up, or
                // at the bottom of nav and trying to scroll down,
                // prevent scrolling from propagating to the parent container.
                if ((atTop && scrollingUp) || (atBottom && scrollingDown)) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            };
    
            nav.addEventListener('wheel', handleWheel, { passive: false });
    
            return () => {
                nav.removeEventListener('wheel', handleWheel);
            };
        }
    }, [category]);

    const activeCategoryPath = useMemo(() => {
        const thisCategoryMatch = writingCategoryRoutes.find(crdm => crdm.section === effectiveSection);
        return thisCategoryMatch ? thisCategoryMatch.path : 'opening-text';
    }, [effectiveSection, writingCategoryRoutes]);

    // const submitEmail = (email) => {

    //     console.log(actions)

    //     dispatch(actions.newsletterSubscribe( email ))
    //         .then((res)=> { 
    //             console.log(res) 
    //             setTimeout(()=>{
    //                 Message.showMessage({
    //                     messageText: 'You are now subscribed',
    //                     duration: 2000,
    //                     preventClickout: false
    //                 });
    //             }, 780) 
    //         })
    //         .catch((err)=> { 
    //             console.log(err) 
    //         });
    // }

    // Set visible article to first article in category.
    // This used to happen naturally from the element observer.
    // Adding large section titles to the top of the page broke this.
    useEffect(() => {
        if( !directLink && effectiveSection && hasWritingSection && !currentArticleId && activeCategoryPath ){
            const articleInCategory = updatedCategoryArticles.find((article)=> article.id == visibleArticleId );
            if( !articleInCategory ){
                const firstArticle = updatedCategoryArticles[0];
                setVisibleArticleId( firstArticle.id );
            }
        }
    }, [directLink, effectiveSection, hasWritingSection, currentArticleId, updatedCategoryArticles, activeCategoryPath]);

    return (
        <div writing-page={activeCategoryPath}>
            {!isMobile && <CommunityMenu activeCategoryPath={activeCategoryPath} /> }
            <div className="section-title">
                <span>{ renderFullTitle(effectiveSection) }</span>
            </div>
            <div className="page-scroll">
                {isMobile && <CommunityMenu activeCategoryPath={activeCategoryPath} /> }
                {!isMobile && (
                    <div className="article-navigation" ref={centerNavRef}>
                        {!directLink ? (
                            hasWritingSection && updatedCategoryArticles.map((article) => {
                                const articleCategoryMatch = writingCategoryRoutes.find(routeData => routeData.section === article.section);
                                
                                const articleRoute = article.section === 'opening_text' ? `/company-writing/#${article.id}` : `/company-writing/${articleCategoryMatch.path}/#${article.id}`;

                                const articleTitle = category === 'sites-in-use' 
                                ? article.numberedTitle
                                : article.title;
                                
                                return (
                                    <div key={article.id}>
                                        <a
                                            ref={article.id == visibleArticleId ? activeLinkRef : null}
                                            href={articleRoute}
                                            className={`button-link${article.id == visibleArticleId ? ' active' : ''}`}
                                            onDragStart={(e) => { e.preventDefault(); }}
                                            onContextMenu={(e) => { e.preventDefault(); }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.history.replaceState(null, null, articleRoute);
                                                scrollToArticle(article.id);
                                            }}
                                        >{articleTitle}</a>
                                    </div>
                                );
                            })
                        ) : (
                            <NavLink
                                exact
                                className="button-link see-all"
                                to={activeCategoryPath !== 'opening-text' ? `/company-writing/${category}` : '/company-writing'}
                                onDragStart={(e) => { e.preventDefault(); }}
                                onContextMenu={(e) => { e.preventDefault(); }}
                            >
                                <span>See All</span>
                            </NavLink>
                        )}
                    </div>
                )}
                <div className="page-window">
                    { ( !directLink && viewportBoundaryObserverRef?.current ) ? (
                        <>
                            <div className="article-content">
                                <div className="article-title description">
                                    { renderDescription(effectiveSection) }

                                    <br />

                                    <span>{effectiveSection !== 'oracle' ? '(' : ''}To receive the full newsletter,&nbsp;</span>

                                    <MessageContext.Consumer>
                                        {(Message) => (
                                            <Button
                                                className="newsletter-sign-up button-link"
                                                label="sign up here"
                                                onClick={(e) => { 
                                                    
                                                    Alert.openModal({
                                                        className: `newsletter-signup dialog${isSafariBrowser ? ' safari' : ''}`,
                                                        type: 'custom',
                                                        header: 'Newsletter signup',
                                                        children: <NewsletterSignupModal 
                                                           Alert={Alert}
                                                           Message={Message}
                                                        />,
                                                        wrapperlessChildren: true,
                                                        preventDefaultEnter: true,
                                                        closeManually: true
                                                    });
                                                    
                                                    // Alert.openModal({
                                                    //     header: 'Newsletter signup',
                                                    //     type: 'dialog',
                                                    //     // placeholder={`Enter your e${'\u200c'}ma${'\u200c'}il a${'\u200c'}dd${'\u200c'}res${'\u200c'}s`},
                                                    //     placeholder: 'Enter your email address...',
                                                    //     className: isSafariBrowser ? 'safari' : '',
                                                    //     focusInput: true,
                                                    //     ignoreUnmount: true,
                                                    //     maxInputLength: 100,
                                                    //     dialogInputType: 'email',
                                                    //     onConfirm: ( email ) => {
                                                    //         // first check if the email is valid
                                                    //         // then if valid — send it
                                                    //         console.log('submit email', email)
                                                    //         submitEmail(email);
                                                    //         // then show a success message
                                                            
                                                    //     }
                                                    // })
                                                }}
                                            />
                                            
                                        )}
                                    </MessageContext.Consumer>

                                    <span>{effectiveSection !== 'oracle' ? ')' : ''}</span>

                                </div>
                            </div>
                            {updatedCategoryArticles.map((article) => (
                                <WritingArticle 
                                    key={article.id}
                                    id={article.id} 
                                    innerRef={(el) => {
                                        if(el) {
                                            viewportBoundaryObserverRef.current?.observe(el);
                                        }
                                    }}
                                    numberedTitle={article.numberedTitle}
                                />
                            ))}
                        </>
                    ) : (
                        <WritingArticle
                            directLink={directLink}
                            id={props.id}
                        />
                   )}
                </div>
            </div>
        </div>
    );
};

export const WritingPage = withRouter(connect(
	function (state, ownProps) {

        // Extract only the category from the URL
        const { category } = ownProps.match.params;

        // Determine effective section based on category
        const categoryMatch = writingCategoryRoutes.find(routeData => routeData.matchPath === category);
        const effectiveSection = categoryMatch?.section || 'opening_text';

        const articles = state.writing.articles
        const hasWritingSection = state.writing.sectionsLoaded.includes(effectiveSection)

        return {
            isMobile: state.homepageState.isMobile,
            category,
            categoryMatch,
            effectiveSection,
            articles,
            hasWritingSection
        };
    },
    function mapDispatchToProps(dispatch) {
        return bindActionCreators({
            fetchWriting: actions.fetchWriting
        }, dispatch);
    }
)(frontloadConnect(async props => { 

    const frontloadPromises = [];

    if(!props.hasWritingSection) {
        frontloadPromises.push(props.fetchWriting({
            section: props.effectiveSection
        }));
    }

    await Promise.allSettled(frontloadPromises);

}, {
    onMount: true,
    onUpdate: false
})(
    WritingPageComponent
)));