import React, { useEffect, useContext, useRef, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter, useHistory } from 'react-router-dom';

import { Button } from '@cargo/ui-kit/button/button';
import { AlertContext } from '@cargo/ui-kit/alert/alert';
import { HotKey } from '@cargo/ui-kit/hotkey/hotkey'
import CloseIcon from "@cargo/common/icons/nav-close.svg";

import { formatDate, convertToEpoch } from '@cargo/common/helpers';

import { isLocalEnv, isDevEnv, isProduction } from '@cargo/common/helpers';

import { validateEmail } from './helpers';

import JobsFilter from './jobs-filter';

import './job-view.scss';

import _ from 'lodash';

const JobView = ({
    user,
    routeInfo,
    actions,
}) => {

    const dispatch = useDispatch();
    const { openModal } = useContext(AlertContext);
    const history = useHistory();

    const isHomePage = useSelector((state) => (state?.homepageState ? true : false));
    const initialJobsFetch = useSelector((state) => state?.jobSiteState ? state?.jobSiteState?.hasUserJobs : state.paginatedJobs?.initialFetch);
    // If on job site, always load. If not on production, always load. If on production and dev access, load.
    // const jobsLocalStorage = !isHomePage || CARGO_ENV !== 'production' || (CARGO_ENV === 'production' && JSON.parse(localStorage.getItem('cargo_dev_access')));
    const jobsLocalStorage = true;
    
    const [ linkCopied, setLinkCopied ] = useState(false);

    useEffect(() => {

        if (!jobsLocalStorage) { return }

        if (!initialJobsFetch && isHomePage) {
            dispatch(actions.fetchJobs());
        }

        if (!initialJobsFetch && !isHomePage) {
            dispatch(actions.fetchUserJobs());
        }

    }, [isHomePage, initialJobsFetch]);

    // Extract jobId from URL parameters
    const { jobId: paramJobId } = useParams();

    const jobIdToFind = paramJobId || null;
    // All jobs in redux.
    const jobs = useSelector((state) => [...state.paginatedJobs.jobs, ...state.userJobs]);
    const userJobs = useSelector((state) => state?.userJobs || []);
    // Preview Job in redux.
    const previewJob = useSelector((state) => state?.jobPreview) || {};
    // Can we edit or delete this job?
    const isMyJob = userJobs.find(job => job.id == jobIdToFind);

    // Find the selected job by ID
    const selectedJob = useMemo(() => {
        if (!jobIdToFind && !_.isEmpty(previewJob)) {
            return previewJob
        }
        return jobs.find((job) => job.id == jobIdToFind) || {};
    }, [jobs, userJobs, jobIdToFind]);

    useEffect(() => {
        // document.querySelector('body').style.overflow = "hidden"
        return () => {
            // document.querySelector('body').style.removeProperty("overflow");
            dispatch({
                type: 'SET_JOB_PREVIEW',
                payload: {}
            });

        }
    }, []);

    const removeView = () => {
        // TODO:
        // This needs to go back to the previous page if it was a job preview.
        // Edit should restore state.
        if (jobIdToFind === null) {
            history.goBack();
            return;
        }

        isHomePage ? history.push(`/jobs`) : history.push("/");
    }

    // Show modal and close overlay if jobs are loaded and job not found
    useEffect(() => {

        // Check if jobs array is not empty and selectedJob is empty
        if (initialJobsFetch && !_.isEmpty(jobs) && _.isEmpty(selectedJob) && jobIdToFind !== null) {
            openModal({
                header: 'Something went wrong.',
                type: 'notice',
                onConfirm: () => { },
                onDeny: () => { },
                onClose: () => {
                    removeView()
                }
            });
        }

        if ( initialJobsFetch && jobIdToFind === null && _.isEmpty(selectedJob) ) {
            removeView()
        }

    }, [jobs, selectedJob, openModal]);

    if ( !initialJobsFetch || ( _.isEmpty(jobs) && _.isEmpty(selectedJob) ) ){
        return (
            <></>
        )
    }

    const { id, profession, employment_type, location, location_map, title, company_name, company_url, apply_url, is_active, updated_at, description } = selectedJob;
    const employmentTypeReadable = employment_type && employment_type.toLowerCase() === 'temporary' ? 'Gig/Project' : employment_type;
    const displayLocation = location === 'Remote' ? 'Remote' : (location === 'onSite' ? location_map?.label : location);
    const lastUpdatedEpoch = updated_at ? convertToEpoch(updated_at) : (Date.now() / 1000);
    const lastUpdated = formatDate(lastUpdatedEpoch * 1000, false);
    const isPreview = history.location.pathname.includes('/preview');
    
    return (
        <div job-page="job" job-id={id || '#'}>
            <HotKey
                shortcut="esc"
                config={{ keyCode: 27 }}
                callback={() => { removeView() }}
                scope="preview"
            />
            {/* <div className="top-line"></div> */}
            <div className={`left-column${!isMyJob && !isPreview ? ' category-filters' : ''}`}>
                <JobsFilter />
            </div>
            <div className="right-column">
                <div className="right-column-inner-1">
                    <div className="company-details ui-group text-ui">
                        <a className="company-name" href={company_url} target="_blank">
                            <span>{company_name}&nbsp;</span>↗
                        </a>
                        <div className="ui-group-spacer"></div>
                        {profession && profession !== 'default' ? <div className="checked">{'\uE001'}{'\u00A0'}{profession}</div> : null}
                        {employment_type && employment_type !== 'default' ? <div className="checked">{'\uE001'}{'\u00A0'}{employmentTypeReadable}</div> : null}
                        {location && location !== 'default' ? <div className="checked">{'\uE001'}{'\u00A0'}{displayLocation}</div> : null}
                        <div className="ui-group-spacer"></div>
                        <div>Posted: {lastUpdated}</div>
                        <Button
                            className={`text-button${linkCopied ? ' link-copied' : ''}`}
                            label={ !linkCopied ? "Copy Link" : "\uE001 Link copied"}
                            onClick={(e) => {
                                const baseUrl = isProduction ? 'https://cargo.site' : isDevEnv ? 'https://dev.cargo.site' : 'https://local.dev.cargo.site';
                                const publicLink = `${baseUrl}/jobs/${id}`;
                                navigator.clipboard.writeText(publicLink);
                                setLinkCopied(true);
                                setTimeout(() => {
                                    setLinkCopied(false);
                                }, 1500);
                            }}
                        />
                    </div>
                    <div className="ui-group text-ui">
                        <div className="ui-group-spacer"></div>
                        <div className="save-cancel-buttons">
                            <Button
                                className="confirm"
                                onClick={(e) => {
                                    if( validateEmail( apply_url ) ){
                                        if( apply_url.toLowerCase().startsWith('mailto:') ){
                                            window.location.href = apply_url;
                                        } else {
                                            window.location.href = 'mailto:' + apply_url;
                                        }
                                        return;
                                    } else {
                                        window.open(apply_url, '_blank');
                                    }
                                }}
                            >Apply <span>↗</span></Button>
                        </div>
                        {isMyJob ? (<>
                            <div className="ui-group-spacer"></div>
                            <Button
                                className="text-button"
                                onClick={(e) => {
                                    history.push(`/edit-job/${id}`)
                                }}
                            >Edit</Button>
                            <Button
                                className="text-button"
                                onClick={(e) => {
                                    openModal({
                                        header: 'Delete Job?',
                                        type: 'confirm',
                                        confirmLabel: 'Delete',
                                        denyLabel: 'Cancel',
                                        onConfirm: () => {
                                            dispatch(actions.deleteJob(id));
                                            removeView();
                                        },
                                        onDeny: () => { },
                                    });
                                }}
                            >Delete</Button>
                        </>) : (null)}
                    </div>
                </div>
                <div className="right-column-inner-2">
                    <div className="ui-group job-details ProseMirror">
                        <div className="job-title">
                            <span>{title}</span>
                        </div>
                        <div className="job-description" dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                </div>
                <span className="button-close">
                    <Button
                        className="close-button"
                        label={<CloseIcon />}
                        onClick={(e) => { removeView() }}
                    />
                </span>
            </div>
        </div>
    );
};

export default withRouter(JobView);