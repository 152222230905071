import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";
import { HOMEPAGE_ORIGIN } from "@cargo/common";
import { NavLink, useHistory } from 'react-router-dom';
import { IntercomSetup } from '../App';

import _ from 'lodash';

import { writingCategoryRoutes } from '../routes';

import { Button } from '@cargo/ui-kit/button/button';

const StaticPageNavigation = ({ 
    closeStaticPage, 
    authenticated, 
    isMobile
}) => {

    const LinkButton = ({label, route}) => {
        return (
            <NavLink exact
                to={route}
                className={`button-link`}
                onDragStart={(e)=>{e.preventDefault();}}
                onContextMenu={(e)=>{e.preventDefault();}}
                onClick={(e)=>{ 
                    const container = document.querySelector('.page-scroll');
                    if (container) {
                        container.scrollTo({ top: 0 });
                    }
                }}
            >{label}</NavLink>
        )
    }

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const extras = (
        <>
            <a
                href="https://instagram.com/cargoworld"
                target="_blank"
                className='button-link external'
            >Instagram</a>

            <br/>

            <span className="contact">
                { IntercomSetup('text') }
            </span>

            <div className="dropdown">
                <Button onClick={toggleDropdown} className={`button-link${isDropdownOpen ? ' open' : ''}`}>
                    Extra Material
                </Button>
                {isDropdownOpen && (
                    <div className="dropdown-items">
                        {_.map(writingCategoryRoutes, (categoryData) => {
                            const { title, matchPath, link, path } = categoryData;
                            if (!matchPath) { return null }
                            const linkRoute = link ? `/company-writing/${link}` : '/company-writing';

                        return (
                                <NavLink exact
                                    key={linkRoute}
                                    to={linkRoute}
                                    className="button-link"
                                    label={title}
                                    route={linkRoute}
                                    activeClassName="selected"
                                    onDragStart={(e) => { e.preventDefault(); }}
                                    onContextMenu={(e) => { e.preventDefault(); }}
                                >
                                    {title}
                                </NavLink>
                            );
                        })}
                        <NavLink
                            exact
                            to="/useful-music"
                            className="button-link"
                            route={'/useful-music'}
                            activeClassName="selected"
                        >Useful Music</NavLink>
                    </div>
                )}
            </div>
        </>
    )

	return <>
		<div className="static-page-navigation">

            <span className="first">
                <LinkButton
                    label="Rates & Services"
                    route="/information"
                />

                <br/>

                <a
                    href="https://docs.cargo.site"
                    target="_blank"
                    className='button-link external'
                >Guides & Docs</a>

                <br/>

                <NavLink exact
                    className={`button-link`} 
                    activeClassName="no-selected-state" 
                    to='/fonts'
                >Fonts</NavLink>
                
                <br/><br/>

                <LinkButton
                    label="Free Use & Students"
                    route="/students"
                />

                {!isMobile && (
                    <>
                        <br/><br/>
                        {extras}
                    </>
                )}
                
            </span>

			<span className="second">

                {isMobile && (
                    <>
                        {extras}
                        <br/>
                    </>
                )}

                <LinkButton
                    label="Terms of Service"
                    route="/terms"
                />
                <br/>

                <LinkButton
                    label="Privacy Policy"
                    route="/privacy"
                />
                <br/>

                <LinkButton
                    label="Copyright Guidelines"
                    route="/copyright-issues"
                />
            
			</span>

		</div>
	</>
}

export default StaticPageNavigation;