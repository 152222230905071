import _ from 'lodash';

import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { writingCategoryRoutes } from './routes';

export const CommunityMenu = ({ activeCategoryPath = null, routeInfo, ...props }) => {

    return ( 
        <>
            <div id="community-menu">
                {routeInfo.isCommunity && (
                    <div className="category">
                        <NavLink exact
                            key={`/community`}
                            to={`/community`}
                            className={`button-link category-link`}
                        >User Work</NavLink>

                        <div className="category-items">
                            <NavLink exact
                                key={`/community`}
                                to={`/community`}
                                className={`button-link all`}
                                activeClassName="selected"
                            >All</NavLink>
                            <NavLink exact
                                key={`/community/in-use`}
                                to={`/community/in-use`}
                                className={`button-link in-use`}
                                activeClassName="selected"
                            >Sites in Use</NavLink>
                            <NavLink exact
                                key={`/community/graphic-design`}
                                to={`/community/graphic-design`}
                                className={`button-link graphic-design`}
                                activeClassName="selected"
                            >Graphic Design</NavLink>
                            <NavLink exact
                                key={`/community/style`}
                                to={`/community/style`}
                                className={`button-link style`}
                                activeClassName="selected"
                            >Style</NavLink>
                            <NavLink exact
                                key={`/community/architecture-design`}
                                to={`/community/architecture-design`}
                                className={`button-link architecture-design`}
                                activeClassName="selected"
                            >Arch. & Design</NavLink>
                            <NavLink exact
                                key={`/community/art`}
                                to={`/community/art`}
                                className={`button-link art`}
                                activeClassName="selected"
                            >Art</NavLink>
                            <NavLink exact
                                key={`/community/photo`}
                                to={`/community/photo`}
                                className={`button-link photo`}
                                activeClassName="selected"
                            >Photo</NavLink>
                            <NavLink exact
                                key={`/community/shops`}
                                to={`/community/shops`}
                                className={`button-link shops`}
                                activeClassName="selected"
                            >Shops</NavLink>
                        </div>

                        <br />

                    </div>

                )}

                <div className="category">
                    <NavLink exact
                        key={`/company-writing`}
                        to={`/company-writing`}
                        className={`button-link category-link`}
                    >Extra Material&nbsp;
                        {routeInfo.isCommunity ? (
                            <span className="fountain-spacer"></span>
                        ) : null}
                    </NavLink>

                    {routeInfo.isWriting || routeInfo.isUsefulMusic ? (
                        <div className="category-items">
                            {_.map(writingCategoryRoutes, (categoryData) => {
                                const { title, matchPath, link, path } = categoryData;
                                if (!matchPath) { return null }
                                const linkRoute = link ? `/company-writing/${link}` : '/company-writing';
                                const forceActive = activeCategoryPath === matchPath;

                                return (
                                    <NavLink exact
                                        key={linkRoute}
                                        to={linkRoute}
                                        className={`button-link${forceActive ? ' selected' : ''}`}
                                        label={title}
                                        route={linkRoute}
                                        activeClassName="selected"
                                        onDragStart={(e) => { e.preventDefault(); }}
                                        onContextMenu={(e) => { e.preventDefault(); }}
                                    >
                                        {title}
                                    </NavLink>
                                );
                            })}
                            <NavLink
                                exact
                                to="/useful-music"
                                className={`button-link${ activeCategoryPath === 'useful-music' ? ' selected' : ''}`}
                                route={'/useful-music'}
                                activeClassName="selected"
                            >Useful Music</NavLink>
                        </div>
                    ) : null }
                </div>
            </div>
            {routeInfo.isCommunity && (
                <div className="community-menu-text" aria-hidden="true">
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    Extra Material&nbsp;
                    <span className="fountain">⛲️</span>
                </div>
            )}
        </>
    )
};

export default withRouter(connect()(CommunityMenu));
