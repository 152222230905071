import React, { useEffect, useMemo, useRef, useState, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { frontloadConnect } from '@cargo/react-frontload';
import { actions } from "../actions";
import { isServer } from "@cargo/common/helpers";
import CommunityMenu from './community-menu';

const usefulMusicRoutes = [
	{ title: "Useful Music", matchPath: undefined, path: 'useful-music', link: "", section: 'useful_music' },
	{ title: "Useful Music", matchPath: 'useful-music', path: 'useful-music', link: "", section: 'useful_music' },
]

const UsefulMusicComponent = props => {

	if (!props.article) {
		return <></>;
	}

	const { id, section, title, credit, content, soundcloud_link, embed_id } = props.article;

	const dinkusPatterns = [
		/\*\*\*/g,
		/\* \* \*/g,
		/\* &nbsp;\* &nbsp;\*/g,
		/\* &nbsp; \* &nbsp; \*/g,
		/❉ ❉ ❉/g,
		/❉ &nbsp;❉ &nbsp;❉/g,
		/﹡﹡﹡/g
	];

	function replaceDinkus(content) {
		dinkusPatterns.forEach(pattern => {
			content = content
				.replace(new RegExp(`${pattern.source}\\s*<br>`, 'g'), '<hr />')
				.replace(new RegExp(`<div[^>]*>\\s*${pattern.source}\\s*<\\/div>`, 'g'), '<hr />')
				.replace(new RegExp(`</div>${pattern.source}`, 'g'), '</div><br /><hr />')
				.replace(pattern, '<hr />')

		});
		return content;
	}

	// format article
	const formattedContent = replaceDinkus(content);

	// Regex to validate URL
	const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/.*)?$/;

    const playIcon = (
        <svg
            vectorEffect="non-scaling-stroke"
            x="0px" y="0px"
            viewBox="0 0 11.3 12"
        >
            <polygon points="0 0 0 12 11.3 6 0 0" />
        </svg>
    );

	return (
		<div
			ref={props.innerRef}
			key={id}
			data-article-id={id}
			id={`${id}`} // Use article id directly for hash linking
			className="article-block"
		// data-article-section={section} // Changed attribute to data-attribute for validity
		>
			<div className="article-content">
				<div className="article-title">
					<a href={soundcloud_link} target="_blank">
						{title}<span className="play">{playIcon}</span>
					</a>
					<div className="credit">{credit}</div>
				</div>				
				<br />

				{/* <iframe
					width="100%"
					height="20"
					scrolling="no"
					frameBorder="no"
					allow="autoplay"
					src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${embed_id}&color=%23ff5500&inverse=false&auto_play=false&show_user=true`}
				></iframe> */}
				<div
					className="article-body"
					dangerouslySetInnerHTML={{ __html: formattedContent }}
				/>
			</div>
		</div>
	);

}

export const UsefulMusic = withRouter(connect(
	function (state, ownProps) {
		return {
			article: state.usefulMusic.articles.find(article => article.id === ownProps.id)
		};

	},
	function mapDispatchToProps(dispatch) {
		return bindActionCreators({
			fetchUsefulMusic: actions.fetchUsefulMusic
		}, dispatch);
	}
)(frontloadConnect(async props => {

	if (!props.article) {
		await props.fetchUsefulMusic({
			id: props.id
		});
	}

}, {
	onMount: true,
	onUpdate: false
})(
	UsefulMusicComponent
)));

const UsefulMusicPageComponent = ({
	actions,
	isMobile,
	location,
	match,
	category,
	categoryMatch,
	effectiveSection,
	articles,
	hasWritingSection,
	directLink,
	...props
}) => {

	// Track last selected article
	const [visibleArticleId, setVisibleArticleId] = useState(null);
	// Tracks initial scroll
	const [hasScrolled, setHasScrolled] = useState(false);
	const firstRender = useRef(true);

	// Filter articles by the effective section
	const categoryArticles = useMemo(() => {
		return articles?.filter(article => article.section === effectiveSection) || [];
	}, [articles, effectiveSection]);

	const updatedCategoryArticles = useMemo(() => {
		return categoryArticles.map((article, index) => {
			return {
				...article,
				numberedTitle: String(categoryArticles.length - index).padStart(5, '0')
			};
		});
	}, [categoryArticles]);

	// Fallback article if no hash is present
	const fallbackArticle = categoryArticles.length > 0 ? categoryArticles[0] : null;

	// Get currentArticleId from the hash (e.g., #123)
	const currentArticleId = useMemo(() => {
		return location.hash ? location.hash.slice(1) : null;
	}, [location.hash]);

	// Refs for each article block
	const activeLinkRef = useRef(null);
	// this needs to be a ref because a state value will be cached
	// in the IntersectionObserver callback body
	const scrollState = useRef({
		scrolling: false,
		currentScrollEvtCallback: null
	});

	// Scroll to the article on first load or hash change
	if (!isServer) {
		useLayoutEffect(() => {
			if (!hasWritingSection || !currentArticleId || hasScrolled) return;

			setTimeout(() => {
				scrollToArticle(currentArticleId);
				setTimeout(() => {
					scrollToArticle(currentArticleId);
					setTimeout(() => {
						scrollToArticle(currentArticleId);
					}, 150);
				}, 100);
			}, 50);
		}, [currentArticleId, categoryArticles, hasWritingSection, hasScrolled]);
	}

	// Fetch articles if we haven't already
	useEffect(() => {
		if (!hasWritingSection && !firstRender.current) {
			props.fetchWriting({
				section: effectiveSection
			})
		}
	}, [effectiveSection]);

	useEffect(() => {
		if (!centerNavRef.current) { return; }
		centerNavRef.current.scrollTo(0, 0);
	}, [categoryArticles]);

	const viewportBoundaryObserverRef = useRef(null);

	useEffect(() => {

		let scrollIntoViewRequested = false;

		const onScroll = () => {

			if (!scrollIntoViewRequested && (!scrollState.current.scrolling || !hasScrolled)) {
				scrollIntoViewRequested = true;
				requestAnimationFrame(() => {
					activeLinkRef.current?.scrollIntoView({
						block: "center",
						behavior: "smooth"
					});
					scrollIntoViewRequested = false;
				})
			}
		}

		document.addEventListener('scroll', onScroll);

		viewportBoundaryObserverRef.current = new IntersectionObserver((entries, o) => {
			entries.forEach(entry => {

				if (!scrollState.current.scrolling && entry.isIntersecting) {
					setVisibleArticleId(entry.target.dataset.articleId);
				}

			})
		}, {
            // https://www.w3.org/TR/intersection-observer/#intersection-observer-interface
            root: null, // "document" is not an element.
			// set margin to a single line 20% below the top of viewport
			rootMargin: '-20% 0px -80% 0px',
			threshold: [0, 1]
		});

		if (firstRender.current) {
			firstRender.current = false;
		}

		return () => {
			viewportBoundaryObserverRef.current.disconnect();
			document.removeEventListener('scroll', onScroll);
		}

	}, [])

	const scrollToArticle = (id) => {

		const yOffset = !isMobile ? -40 : -133;
		const element = document.getElementById(id);

		if (!element) {
			return;
		}

		const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

		// kill previous listener
		if (scrollState.current.currentScrollEvtCallback) {
			scrollState.current.currentScrollEvtCallback.cancel();
			document.removeEventListener('scroll', scrollState.current.currentScrollEvtCallback);
		}

		// scroll ends when no scroll happened in the last 100ms
		const onScrollEnd = _.debounce(() => {
			scrollState.current.scrolling = false;
			document.removeEventListener('scroll', onScrollEnd);
			scrollState.current.currentScrollEvtCallback = null;
		}, 100);

		document.addEventListener('scroll', onScrollEnd);

		scrollState.current.scrolling = true;
		scrollState.current.currentScrollEvtCallback = onScrollEnd;

		window.scrollTo({ top: y, behavior: 'smooth' });

		setVisibleArticleId(id);

		setHasScrolled(true);

	};

	// Prevent scrolling of parent container when over scrolling within the navigation.
	const centerNavRef = useRef(null);

	useEffect(() => {
		const nav = centerNavRef.current;

		const handleWheel = (e) => {
			const { scrollTop, scrollHeight, clientHeight } = nav;
			const atTop = scrollTop === 0;
			const atBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
			const scrollingUp = e.deltaY < 0;
			const scrollingDown = e.deltaY > 0;

			// If at the top of nav and trying to scroll up, or
			// at the bottom of nav and trying to scroll down,
			// prevent scrolling from propagating to the parent container.
			if ((atTop && scrollingUp) || (atBottom && scrollingDown)) {
				e.preventDefault();
				e.stopPropagation();
			}
		};

		nav.addEventListener('wheel', handleWheel, { passive: false });

		return () => {
			nav.removeEventListener('wheel', handleWheel);
		};
	}, [category]);

	const activeCategoryPath = useMemo(() => {
		const thisCategoryMatch = usefulMusicRoutes.find(crdm => crdm.section === effectiveSection);
		return thisCategoryMatch ? thisCategoryMatch.path : 'useful-music';
	}, [effectiveSection, usefulMusicRoutes]);

	return (
		<div writing-page={activeCategoryPath}>
            {!isMobile && <CommunityMenu activeCategoryPath={activeCategoryPath} /> }
			<div className="section-title">
				<span>Useful Music</span>
			</div>
			<div className="page-scroll">
				{isMobile && <CommunityMenu activeCategoryPath={activeCategoryPath} /> }
				<div className="article-navigation" ref={centerNavRef}>
					{!directLink ? (
						hasWritingSection && updatedCategoryArticles.map((article) => {
							const articleCategoryMatch = usefulMusicRoutes.find(routeData => routeData.section === article.section);

							const articleRoute = article.section === 'useful_music' ? `/useful-music/#${article.id}` : `/useful-music/${articleCategoryMatch.path}/#${article.id}`;

							const articleTitle = category === 'sites-in-use'
								? article.numberedTitle
								: article.title;

							return (
								<div key={article.id}>
									<a
										ref={article.id == visibleArticleId ? activeLinkRef : null}
										href={articleRoute}
										className={`button-link${article.id == visibleArticleId ? ' active' : ''}`}
										onDragStart={(e) => { e.preventDefault(); }}
										onContextMenu={(e) => { e.preventDefault(); }}
										onClick={(e) => {
											// console.log("currentArticle", currentArticle)
											e.preventDefault();
											window.history.replaceState(null, null, articleRoute);
											scrollToArticle(article.id);
										}}
									>{articleTitle}</a>
								</div>
							);
						})
					) : (
						<NavLink
							exact
							className="button-link see-all"
							to={activeCategoryPath !== 'useful-music' ? `/writing/${category}` : '/writing'}
							onDragStart={(e) => { e.preventDefault(); }}
							onContextMenu={(e) => { e.preventDefault(); }}
						>
							See All
						</NavLink>
					)}
				</div>
				<div className="page-window">
					{(!directLink && viewportBoundaryObserverRef?.current) ? (
						<>
							<div className="article-content">
								<div className="article-title description">
									So-called <i>ambient music</i> is likely the center of our musical tastes. We find such (primarily) non-beat, non-vocal, music useful for the daily progression of our undertakings (<i>useful</i> in its ability to stimulate but not <i>overly-color</i> a mood).<br /><br />This <i>ambient zone</i> is pretty wide; at any given time the atmospheres can be pastoral, benevolent, serial, abstract, drone-y, icy, folky, minimal, shadowy, or what have you.
								</div>
							</div>
							{updatedCategoryArticles.map((article) => (
								<UsefulMusic
									key={article.id}
									id={article.id}
									innerRef={(el) => {
										if (el) {
											viewportBoundaryObserverRef.current?.observe(el);
										}
									}}
									numberedTitle={article.numberedTitle}
								/>
							))}
						</>
					) : (
						<UsefulMusic
							directLink={directLink}
							id={props.id}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export const UsefulMusicPage = withRouter(connect(
	function (state, ownProps) {

		// Extract only the category from the URL
		const { category } = ownProps.match.params;

		// Determine effective section based on category
		const categoryMatch = 'useful_music';
		const effectiveSection = 'useful_music';

		const articles = state.usefulMusic.articles
		const hasWritingSection = state.usefulMusic.sectionsLoaded.includes(effectiveSection)

		return {
			isMobile: state.homepageState.isMobile,
			category,
			categoryMatch,
			effectiveSection,
			articles,
			hasWritingSection
		};
	},
	function mapDispatchToProps(dispatch) {
		return bindActionCreators({
			fetchUsefulMusic: actions.fetchUsefulMusic
		}, dispatch);
	}
)(frontloadConnect(async props => {

	const frontloadPromises = [];

	if (!props.hasWritingSection) {
		frontloadPromises.push(props.fetchUsefulMusic({
			section: props.effectiveSection
		}));
	}

	await Promise.allSettled(frontloadPromises);

}, {
	onMount: true,
	onUpdate: false
})(
	UsefulMusicPageComponent
)));