import { interpolate, bezierEasing, applyStyles } from './helpers';

// Weak map to store IDs for animation.
// This allows us to cancel animations more than one is enacted on the same element.
const animationIds = new WeakMap(); 

export const animateStyles = (element, initial, animate, duration, delay, easingFunction, cleanup = true, animationComplete) => {

    if(!element) {
        return;
    }

    // Cancel any existing animation on this element
    if (animationIds.has(element)) {
        const currentAnimation = animationIds.get(element);
        currentAnimation.cancelled = true;
    }

    // Register a new cancellation for this animation
    const currentAnimation = { cancelled: false };
    animationIds.set(element, currentAnimation );

    const startTime = performance.now() + delay; // Add delay to start time

    const step = (currentTime) => {
        // Check for cancellation.
        if( currentAnimation.cancelled ) {
            // if (cleanup) {
            //     element.removeAttribute('style');
            // }
            animationComplete();
            return
        }

        const elapsedTime = currentTime - startTime;
        const progress = Math.min(Math.max(elapsedTime / (duration * 1000), 0), 1);

        if (progress > 0 || delay === 0) { // Only start updating styles after delay
            const easedProgress = easingFunction(progress); // Apply easing
            const interpolatedStyles = {};
            for (const key in animate) {
                interpolatedStyles[key] = interpolate(initial[key], animate[key], easedProgress);
            }
            applyStyles(element, interpolatedStyles);
        }

        if (progress < 1) {
            requestAnimationFrame(step);
        } else {
            if (cleanup) {
                element.removeAttribute('style');
            }
            animationIds.delete(element);
            animationComplete();
        }
    };

    requestAnimationFrame(step);
};