import React from 'react';
import { connect, useSelector, useDispatch} from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import LoginMenu from './login-menu';
import { AnimatedElement, fadeInGlideUp1, fadeInGlideUp3, useAnimationPath } from "@cargo/common/animation-context";
import FolderArrow from "@cargo/common/icons/folder-arrow.svg";

const HomeMenu = ({ layeredRouter, returnTo, hasLinks }) => {

    const isMobile = useSelector((state) => state.homepageState.isMobile);
    const authenticated = useSelector((state) => state.auth.authenticated);

    const ReturnArea = ({ returnTo }) => (
        <div className="area return-area">
            <NavLink
                className="button-link"
                draggable={false}
                to={returnTo}
                activeClassName='selected'
                onContextMenu={(e) => { e.preventDefault(); }}
            >
                <FolderArrow />
            </NavLink>
        </div>
    );

    // check what the top layer has rendered
    const { isTemplates, isCommunity, isHomePage, isFonts, isStaticPage } = _.last(layeredRouter.layerStack).routeInfo;

	// For triggering the text reveal fade animation on page load
    const initialLocation = layeredRouter.getInitialRouteOnLoad();

    const shouldApplyInitialStyles = useAnimationPath('fade', '/', !authenticated, initialLocation.pathname);
    // const overlayRoutePresent = layeredRouter.layerStack.length > 1;

    return (
        <div id="home-menu">
            {returnTo ? (
                <ReturnArea returnTo={returnTo} />
            ) :  (
                !isMobile && <div className="left-area">
                    <AnimatedElement
                        as="span"
                        animation={fadeInGlideUp3}
                        animationKey="fade"
                        applyInitialStyles={shouldApplyInitialStyles}
                    >
                    {isHomePage ? (
                        <span className="button-link intro">
                            <span>Cargo</span>
                        </span>
                    ) : (
                        <NavLink exact
                            className={`button-link`}
                            to='/'>
                            <span>Cargo</span>
                        </NavLink>
                    )}
                    </AnimatedElement>
                </div>
            )}

            {hasLinks ? (
                <div className="right-area">
                     <AnimatedElement
                        as="span"
                        animation={fadeInGlideUp3}
                        animationKey="fade"
                        applyInitialStyles={shouldApplyInitialStyles}
                    >
                        <NavLink exact
                            className={`button-link templates`}
                            isActive={() => isTemplates}
                            activeClassName="selected"
                            to='/templates'
                        >
                            <span>Templates</span>
                        </NavLink>

                        <NavLink exact
                            className={`button-link community`}
                            isActive={() => isCommunity}
                            activeClassName="selected"
                            to='/community'
                        >
                            <span>Community</span>
                        </NavLink>

                        <NavLink exact
                            className={`button-link`}
                            isActive={() => isStaticPage}
                            activeClassName="selected"
                            to='/information'
                        >
                            <span>Information</span>
                        </NavLink>
                    </AnimatedElement> 
                    {!authenticated ? <LoginMenu /> : null}
                </div>
            ) : null }
        </div>
    );
}

export default withRouter(HomeMenu);