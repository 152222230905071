import React, { useEffect, useContext, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../actions";
import { useHistory, useParams, withRouter} from 'react-router-dom';
import _ from 'lodash';

import { IntercomSetup } from './App';

import StaticPageNavigation from './static-pages/static-page-navigation';
import { staticPageComponents } from './routes';
import Flipper from './flipper';

const InformationPageHandler = ({ authenticated, isMobile, layeredRouter }) => {

    // pull params from router match to get the current page
    const { page, closeOverlay } = useParams();
    const LazyPage = staticPageComponents[page];
    const history = useHistory();

    const overlayRef = useRef();
    const clickOriginInOverlay = useRef(null);

    const isShortPage = page == "information" || page == "students";

    const staticPageOnTop = _.get( _.last(layeredRouter.layerStack ), 'routeInfo.isStaticPage', false);

    const listenersBound = useRef(false); // To track whether listeners are currently bound

    useEffect(() => {
        
        const handlePointerDown = (event) => {
            const target = event.target;
            const inOverlay = overlayRef.current?.contains(target);
            const isException = isClickException(target);

            if (inOverlay || isException) {
                clickOriginInOverlay.current = true;
            } else {
                clickOriginInOverlay.current = false;
            }
        };

        const handlePointerUp = (event) => {

            if (clickOriginInOverlay.current === null ) {
                return;
            }
    
            const target = event.target;
            const inOverlay = overlayRef.current?.contains(target);
            const isException = isClickException(target);
            const clickIsOnBackground = authenticated && target.classList.contains('background') && target.closest('[static-page="information"]');
    
            if (( !inOverlay && !isException && !clickOriginInOverlay.current ) || clickIsOnBackground ) {
                layeredRouter.closeOverlay();
            }
    
            clickOriginInOverlay.current = null; // Reset after the pointerup event

        };

        const onKeyDown = (event) => {
            if (event?.key === 'Escape') {
                event.preventDefault();
                event.stopImmediatePropagation();
                layeredRouter.closeOverlay();
            }
        };

        if (authenticated && staticPageOnTop && !listenersBound.current) {
            window.addEventListener("pointerdown", handlePointerDown);
            window.addEventListener("pointerup", handlePointerUp);
            window.addEventListener("keydown", onKeyDown);
            listenersBound.current = true; // Mark listeners as bound
        } else if ((!authenticated || !staticPageOnTop) && listenersBound.current) {
            // Unbind event listeners...
            window.removeEventListener("pointerdown", handlePointerDown);
            window.removeEventListener("pointerup", handlePointerUp);
            window.removeEventListener("keydown", onKeyDown);
            listenersBound.current = false; // Mark listeners as unbound
        }

        return () => {
            // Clean up event listeners on component unmount
            if (listenersBound.current) {
                window.removeEventListener("pointerdown", handlePointerDown);
                window.removeEventListener("pointerup", handlePointerUp);
                window.removeEventListener("keydown", onKeyDown);
                listenersBound.current = false; // Ensure listeners are marked as unbound
            }
        };
    }, [authenticated, staticPageOnTop]);

    const isClickException = (target) => {
        const exceptionSelectors = ['.context-menu-layer', '#uiWindow-layer'];
        return exceptionSelectors.some(selector => target.closest(selector));
    };

    return (
        <div static-page={page}>
            <div className="background"></div>
            <div className="page-scroll">
                <StaticPageNavigation 
                    isMobile={isMobile} 
                    closeStaticPage={() => { layeredRouter.closeOverlay() }} 
                />
                <div className="page-window">
                    <div className={`page${isShortPage ? ' short' : ''}`}>
                        {!LazyPage ? (null) : <LazyPage />}
                    </div>
                </div>
            </div>
            {/* {!isMobile && <Flipper />} */}
        </div>
    )
}

function mapReduxStateToProps(state, ownProps) {
    return {
        authenticated: state.auth.authenticated,
        isMobile: state.homepageState.isMobile
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators({
        addUIWindow: actions.addUIWindow
    }, dispatch);

}
export default withRouter(
	connect(
		mapReduxStateToProps, 
		mapDispatchToProps
	)(InformationPageHandler)
);