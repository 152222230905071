import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import JobsFilter from './jobs-filter';
import { useJobsListScroll } from './use-jobs-list-scroll';

import './all-jobs.scss';

const AllJobs = ({ routeInfo, match, actions }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const filteredJobs = useSelector((state) => state.filteredJobs);
    const jobsFilter = useSelector((state) => state.jobsFilter);

    const isMyListings = routeInfo?.isMyListing;
    const isHomePage = useSelector((state) => (state?.homepageState ? true : false));
    const initialJobsFetch = useSelector((state) => state?.jobSiteState ? state?.jobSiteState?.hasUserJobs : state.paginatedJobs?.initialFetch);

    // const jobsLocalStorage = !isHomePage || CARGO_ENV !== 'production' || (CARGO_ENV === 'production' && JSON.parse(localStorage.getItem('cargo_dev_access')));
    const jobsLocalStorage = true;

    const jobListRef = useRef(null);
    const jobScrollRef = useRef(null);


    const handleScroll = useJobsListScroll(
        jobScrollRef,
        initialJobsFetch,
        filteredJobs.length,
        jobsFilter
    );

    useEffect(() => {

        if (!jobsLocalStorage) { return }

        if (!initialJobsFetch && isHomePage) {
            dispatch(actions.fetchJobs());
        }

        if (!initialJobsFetch && !isHomePage) {
            dispatch(actions.fetchUserJobs());
        }
    }, [isHomePage, initialJobsFetch]);


    const setJobsFIlter = (change) => {
        if (!isHomePage) { return }
        dispatch({
            type: 'SET_MULTI_JOBS_FILTERS',
            payload: change
        });

    }

    return (
        <div
            job-page="all-jobs"
            ref={jobScrollRef}
            onScroll={(e) => { handleScroll(e) }}
        >
            {/* <div className="top-line"></div> */}
            <div className={`left-column${!isMyListings ? ' category-filters' : ''}`}>
                <JobsFilter />
            </div>
            <div className="right-column">

                {filteredJobs.length === 0 && (initialJobsFetch || !jobsLocalStorage) ? (
                    <div className="no-jobs">No Jobs</div>
                ) : null}
                <div
                    className={`job-list${isMyListings ? ' my-listings' : ''}`}
                    ref={jobListRef}
                >
                    {filteredJobs.map((job, index) => {

                        const employmentType = job.employment_type && job.employment_type.toLowerCase() === 'temporary' ? 'Gig/Project' : job.employment_type === 'default' ? null : job.employment_type;
                        const displayLocation = job.location === 'default' ? null : job.location === 'Remote' ? 'Remote' : (job.location === 'onSite' ? job.location_map?.label : job.location);

                        if (!job.is_active && !isMyListings) {
                            return null;
                        }

                        return (
                            <div
                                key={job.id}
                                job-id={job.id}
                                className={`job-listing${!job.is_active ? ' inactive' : ''}`}
                                onClick={(e) => {
                                    if (e.target.closest('.job-location') && isHomePage) {
                                        return;
                                    }
                                    if (e.target.closest('.employment-type') && isHomePage) {
                                        return;
                                    }
                                    if (isHomePage) {
                                        history.push(match.url.replace(/\/$/, '') + '/' + job.id, {
                                            preventScroll: true,
                                        });
                                    } else {
                                        history.push(match.url.replace(/\/$/, '') + '/job/' + job.id, {
                                            preventScroll: true,
                                        });
                                    }
                                }}
                            >
                                <div className="company-name">{job.company_name}</div>
                                <div className="job-title">{job.title}</div>
                                <div className="type-location">
                                    {employmentType ?
                                        <div
                                            className={`employment-type${!isHomePage ? ' no-hover' : ''}`}
                                            onClick={() => {
                                                setJobsFIlter({
                                                    fullTime: job.employment_type === 'Full Time',
                                                    partTime: job.employment_type === 'Part Time',
                                                    temporary: job.employment_type === 'temporary',
                                                })
                                            }}
                                        >{employmentType}</div>
                                        : <div className="employment-type"></div>}
                                    {employmentType && displayLocation ? (<>,{'\u00A0'}</>) : null}
                                    {displayLocation ?
                                        <div
                                            className={`job-location${!isHomePage ? ' no-hover' : ''}`}
                                            onClick={() => {
                                                if (displayLocation === 'Remote') {
                                                    setJobsFIlter({ 'remote': true, 'onSite': false })
                                                } else {
                                                    setJobsFIlter({
                                                        'onSite': true,
                                                        'remote': false,
                                                        'location': job.location_map.label
                                                    })
                                                }
                                            }}
                                        >{displayLocation}</div>
                                        : <div className="job-location"></div>}
                                </div>
                            </div>
                        );
                    })}
                    <div className="job-listing filler"></div>
                    <div className="job-listing filler"></div>
                    <div className="job-listing filler"></div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(AllJobs);